import React from 'react';
import {Form, Row, Col, Input, InputNumber, DatePicker, Button, Card, Select, Checkbox, Upload, Divider} from 'antd';
import { UploadOutlined, InboxOutlined } from '@ant-design/icons';
import SelectData from './selectdata';
import {useHistory} from "react-router-dom";

import StaticSelectData from "./StaticSelectData";
import moment from "moment";
import 'moment/locale/es-us';
import locale from 'antd/lib/locale/en_US';
import {CisUI} from "./CISUI";
import IntlMessages from "./IntlMessages";



const { Option } = Select;
// const Option = Select.Option;
const {TextArea} = Input;

function handleChange(value) {
    // console.log(`selected ${value}`);
}

const normFile = e => {
    // console.log('Upload event:', e);

    if (Array.isArray(e)) {
        return e;
    }

    return e && e.fileList;
};

const formItemLayout = {
    labelCol: {
        span: 24,
    },
    wrapperCol: {
        span: 24,
    },
};


const InputFields = (inputs, values= [], isEdit=0,props=[], onchange) => {
    const history = useHistory();

    if(isEdit === 1) {
        values = JSON.parse(JSON.stringify(values).replace(/\:null/gi, "\:\"\""));
    }

    const getFields = () => {
        const count = inputs.length;
        const children = [];
        inputs.map((list, index) => {
            // console.log(list);
            let field = "";
            let hide = 0;
            if(list.isHide === 1) {
                hide = 1;
            }

            if(hide === 0) {
                if (list.type === 'text' || list.type === 'phone' || list.type === 'email') {
                    let InV = values[`${list.name}`];

                    // let InV = "Hello";
                    // console.log(InV)
                    field = <Form.Item
                        id={`${list.name}`}
                        label={<IntlMessages id={`${list.label}`} />}
                        name={`${list.name}`}
                        // initialValue={`${InV}`}
                        className={`${list.class || ""}`}
                        onChange={handleChange}
                        rules={[
                            {
                                required: list.required,
                                message: list.error_msg
                            },
                        ]}
                    >

                        <Input placeholder={list.placeholder} disabled={list.readonly}/>

                        {/*<Input name={list.name} value={InV} placeholder={list.label}/>*/}

                    </Form.Item>
                } else if(list.type === 'divider'){
                    field = <Divider orientation="left" plain><span style={{ fontSize: "16px", fontWeight: "bold"}}>{list.title}</span></Divider>;
                } else if (list.type === 'number') {
                    field = <Form.Item
                        id={`${list.name}`}
                        label={<IntlMessages id={`${list.label}`} />}
                        name={`${list.name}`}
                        className={`${list.class || ""}`}
                        onChange={handleChange}
                        // initialValue={`${InV}`}
                        rules={[
                            {
                                required: list.required,
                                message: list.error_msg
                            },
                        ]}
                    >
                        <InputNumber className="gx-mb-3 gx-w-100" placeholder={list.placeholder}/>
                    </Form.Item>

                } else if (list.type === 'date') {
                    field = <Form.Item
                        id={`${list.name}`}
                        label={<IntlMessages id={`${list.label}`} />}
                        name={`${list.name}`}
                        className={`${list.class || ""}`}
                        onChange={handleChange}
                        initialValue={moment()}
                        rules={[
                            {
                                required: list.required,
                                message: list.error_msg
                            },
                        ]}
                    >
                        <DatePicker allowClear format={CisUI().dateFormat} className="gx-mb-3 gx-w-100"
                                    placeholder={list.placeholder}/>

                    </Form.Item>
                } else if (list.type === 'month') {
                    field = <Form.Item
                        id={`${list.name}`}
                        label={<IntlMessages id={`${list.label}`} />}
                        name={`${list.name}`}
                        className={`${list.class || ""}`}
                        initialValue={moment()}
                        rules={[
                            {
                                required: list.required,
                                message: list.error_msg
                            },
                        ]}
                    >
                        <DatePicker picker="month" defaultValue={moment()} format={CisUI().dateFormat} className="gx-mb-3 gx-w-100"
                                    placeholder={list.placeholder}/>

                    </Form.Item>
                } else if (list.type === 'password') {
                    field = <Form.Item
                        id={`${list.name}`}
                        name={`${list.name}`}
                        className={`${list.class || ""}`}
                        label={<IntlMessages id={`${list.label}`} />}
                        autoComplete="new-password"
                        rules={[
                            {
                                required: list.required,
                                message: list.error_msg
                            },
                        ]}
                    >
                        <Input.Password placeholder={list.placeholder}/>
                    </Form.Item>

                } else if (list.type === 'select') {

                    const dataOptions = SelectData(list.data);
                    const dataOptions1 = StaticSelectData(list.name);
                    let ds = null;
                    if ((list.value !== "") || (list.value >0)) {
                        ds = list.value;
                    }

                    if(list.name === 'company_id' || list.name === 'com_id') {
                        const companyID = CisUI().getUserInfo('com_id');
                        if(companyID > 0) {

                        }
                        else {
                            field = <Form.Item
                                name={`${list.name}`}
                                label={<IntlMessages id={`${list.label}`} />}
                                initialValue={ds}
                                rules={[
                                    {
                                        required: list.required,
                                        message: list.placeholder
                                    },
                                ]}
                            >

                                <Select
                                    showSearch
                                    placeholder={list.placeholder}
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    // defaultValue={`${InV}`}
                                    onChange={handleChange}
                                >
                                    {
                                        list.isStatic === 1 && (
                                            dataOptions1.map((items, index) =>
                                                <Option key={++index} value={items.id}>{items.name}</Option>
                                            )
                                        )
                                    }
                                    {
                                        dataOptions.map((items, index) =>
                                            <Option key={++index} value={items.id}>{items.name}</Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        }
                    }
                    else if(list.name === 'local_id') {
                        const userArea = CisUI().getUserInfo('comarea');
                        if(userArea >= 6) {

                        }
                        else {
                            field = <Form.Item
                                name={`${list.name}`}
                                label={<IntlMessages id={`${list.label}`} />}
                                initialValue={ds}
                                rules={[
                                    {
                                        required: list.required,
                                        message: list.placeholder
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    placeholder={list.placeholder}
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    // defaultValue={`${InV}`}
                                    onChange={handleChange}
                                >
                                    {
                                        list.isStatic === 1 && (
                                            dataOptions1.map((items, index) =>
                                                <Option key={++index} value={items.id}>{items.name}</Option>
                                            )
                                        )
                                    }
                                    {
                                        dataOptions.map((items, index) =>
                                            <Option key={++index} value={items.id}>{items.name}</Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        }
                    }
                    else if(list.name === 'district_id') {
                        const userArea = CisUI().getUserInfo('comarea');
                        if(userArea >= 5) {

                        }
                        else {
                            field = <Form.Item
                                name={`${list.name}`}
                                label={<IntlMessages id={`${list.label}`} />}
                                initialValue={ds}
                                rules={[
                                    {
                                        required: list.required,
                                        message: list.placeholder
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    placeholder={list.placeholder}
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    // defaultValue={`${InV}`}
                                    onChange={handleChange}
                                >
                                    {
                                        list.isStatic === 1 && (
                                            dataOptions1.map((items, index) =>
                                                <Option key={++index} value={items.id}>{items.name}</Option>
                                            )
                                        )
                                    }
                                    {
                                        dataOptions.map((items, index) =>
                                            <Option key={++index} value={items.id}>{items.name}</Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        }
                    }
                    else if(list.name === 'region_id') {
                        const userArea = CisUI().getUserInfo('comarea');
                        if(userArea >= 4) {

                        }
                        else {
                            field = <Form.Item
                                name={`${list.name}`}
                                label={<IntlMessages id={`${list.label}`} />}
                                initialValue={ds}
                                rules={[
                                    {
                                        required: list.required,
                                        message: list.placeholder
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    placeholder={list.placeholder}
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    // defaultValue={`${InV}`}
                                    onChange={handleChange}
                                >
                                    {
                                        list.isStatic === 1 && (
                                            dataOptions1.map((items, index) =>
                                                <Option key={++index} value={items.id}>{items.name}</Option>
                                            )
                                        )
                                    }
                                    {
                                        dataOptions.map((items, index) =>
                                            <Option key={++index} value={items.id}>{items.name}</Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        }
                    }
                    else if(list.name === 'ast_id') {
                        field = <Form.Item
                            name={`${list.name}`}
                            label={<IntlMessages id={`${list.label}`} />}
                            initialValue={ds}
                            rules={[
                                {
                                    required: list.required,
                                    message: list.placeholder
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                placeholder={list.placeholder}
                                optionFilterProp="children"
                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                // defaultValue={`${InV}`}
                                onChange={handleChange}
                            >
                                {
                                    list.isStatic === 1 && (
                                        dataOptions1.map((items, index) =>
                                            <Option key={++index} value={items.id}>{items.name}</Option>
                                        )
                                    )
                                }
                                {
                                    dataOptions.map((items, index) =>
                                        <Option key={++index} value={items.id}>{items.name} ({items.code})</Option>
                                    )
                                }
                            </Select>
                        </Form.Item>

                    }
                    else {

                        field = <Form.Item
                            name={`${list.name}`}
                            label={<IntlMessages id={`${list.label}`} />}
                            initialValue={ds}
                            rules={[
                                {
                                    required: list.required,
                                    message: list.placeholder
                                },
                            ]}
                        >

                            <Select
                                showSearch
                                placeholder={list.placeholder}
                                optionFilterProp="children"
                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                // defaultValue={`${InV}`}
                                // onChange={onchange}
                            >
                                {
                                    list.isStatic === 1 && (
                                        dataOptions1.map((items, index) =>
                                            <Option key={++index} value={items.id}>{items.name}</Option>
                                        )
                                    )
                                }
                                {
                                    dataOptions.map((items, index) =>
                                        <Option key={++index} value={items.id}>{items.name}</Option>
                                    )
                                }
                            </Select>
                        </Form.Item>
                    }

                } else if (list.type === 'textarea') {
                    const InV = values[`${list.name}`];
                    field = <Form.Item
                        id={`${list.name}`}
                        name={`${list.name}`}
                        className={`${list.class || ""}`}
                        label={<IntlMessages id={`${list.label}`} />}
                        rules={[
                            {
                                required: list.required,
                                message: list.error_msg
                            },
                        ]}
                    >
                        <TextArea placeholder={list.placeholder} rows={2}/>
                    </Form.Item>
                } else if (list.type === 'file') {
                    field =
                        <Form.Item
                            name={`${list.name}`}
                            label={<IntlMessages id={`${list.label}`} />}
                            valuePropName="fileList"
                            className={`${list.class || ""}`}
                            getValueFromEvent={normFile}
                            extra=""
                            rules={[
                                {
                                    required: list.required,
                                    message: list.error_msg
                                },
                            ]}
                        >
                            <Upload name="logo" action="/upload.do" listType="picture">
                                <Button>
                                    <UploadOutlined/> Click to upload
                                </Button>
                            </Upload>
                        </Form.Item>
                } else if (list.type === 'checkbox') {
                    field = <Form.Item
                        name={`${list.name}`}
                        className={`${list.class || ""}`}
                        label={<IntlMessages id={`${list.label}`} />}
                        // initialValue="true"
                        valuePropName="checked"
                    >
                        <Checkbox
                            onChange={onchange}
                            // defaultChecked="false"
                        >
                            {list.value_label} {list.text}
                        </Checkbox>

                    </Form.Item>
                }
                if(field != "") {
                    children.push(
                        <Col lg={list.size * 2} md={list.size * 2} sm={12} xs={24} key={index}
                             style={{display: index < count ? 'block' : 'none'}}>
                            <div className="gx-form-row0">
                                {field}
                            </div>
                        </Col>,
                    );
                }
            }
        });
        return children;
    };

    const getFieldsEdit = () => {
        const count = inputs.length;
        const children = [];
        inputs.map((list, index) => {
            let field = "";
            const InV = values[`${list.name}`];
            if(list.type === 'text' || list.type === 'phone' || list.type === 'email') {
                if(InV != null) {
                    field = <Form.Item
                        id={`${list.name}`}
                        label={<IntlMessages id={`${list.label}`} />}
                        name={`${list.name}`}
                        initialValue={InV}
                        rules={[
                            {
                                required: list.required,
                                message: list.error_msg
                            }
                        ]}
                    >
                        <Input placeholder={list.placeholder}/>
                    </Form.Item>
                }
            }
            else if(list.type === 'number'){
                // console.log(InV);
                if(InV != null) {
                    field = <Form.Item
                        id={`${list.name}`}
                        label={<IntlMessages id={`${list.label}`} />}
                        initialValue={InV}
                        name={`${list.name}`}
                        rules={[
                            {
                                required: list.required,
                                message: list.error_msg
                            },
                        ]}
                    >
                        <InputNumber className="gx-mb-3 gx-w-100" placeholder={list.placeholder}/>
                    </Form.Item>
                }
            }
            else if(list.type === 'date'){
                if(InV != null) {
                    // console.log(InV);
                    field = <Form.Item
                        id={`${list.name}`}
                        label={<IntlMessages id={`${list.label}`} />}
                        initialValue={moment(InV)}
                        name={`${list.name}`}
                        rules={[
                            {
                                required: list.required,
                                message: list.error_msg
                            },
                        ]}
                    >
                        <DatePicker className="gx-mb-3 gx-w-100" placeholder={list.placeholder}/>
                    </Form.Item>
                }
            }
            else if(list.type === 'date'){
                if(InV != null) {
                    // console.log(InV);
                    field = <Form.Item
                        id={`${list.name}`}
                        label={<IntlMessages id={`${list.label}`} />}
                        initialValue={moment(InV)}
                        name={`${list.name}`}
                        rules={[
                            {
                                required: list.required,
                                message: list.error_msg
                            },
                        ]}
                    >
                        <DatePicker picker="month" className="gx-mb-3 gx-w-100" placeholder={list.placeholder}/>
                    </Form.Item>
                }
            }
            else if(list.type === 'password') {
                field = <Form.Item
                    name={`${list.name}`}
                    label={<IntlMessages id={`${list.label}`} />}
                    rules={[
                        {
                            required: false,
                            message: list.error_msg
                        },
                    ]}
                >
                    <Input.Password placeholder={list.placeholder} />
                </Form.Item>

            }
            else if(list.type === 'select') {

                // if(list.name === 'company_id' || list.name === 'com_id') {
                //
                //     const companyID = CisUI().getUserInfo('com_id');
                //     if (companyID > 0) {
                //
                //     } else {
                //         const dataOptions = SelectData(list.data);
                //         const dataOptions1 = StaticSelectData(list.name);
                //         // console.log("CompanyList : "+dataOptions);
                //         if(InV != null) {
                //             // console.log(dataOptions)
                //             // console.log("Selected Data: "+list.name+":"+InV);
                //             field = <Form.Item
                //                 name={`${list.name}`}
                //                 label={<IntlMessages id={`${list.label}`} />}
                //                 initialValue={InV}
                //                 rules={[
                //                     {
                //                         required: list.required,
                //                         message: list.error_msg
                //                     },
                //                 ]}
                //             >
                //                 <Select
                //                     showSearch
                //                     placeholder={list.placeholder}
                //                     optionFilterProp="children"
                //                     filterOption={(input, option) => option.props.children?.toString().toLowerCase().indexOf(input?.toLowerCase()) >= 0 || false}
                //                 >
                //                     {
                //                         list.isStatic === 1 && (
                //                             dataOptions1.map((items, index) =>
                //                                 <Option key={++index} value={items.id} >{items.name}</Option>
                //                             )
                //                         )
                //                     }
                //                     {
                //                         dataOptions.map((items, index) =>
                //                             <Option key={++index} value={items.id} >{items.name}</Option>
                //                         )
                //                     }
                //                 </Select>
                //             </Form.Item>
                //         }
                //     }
                // }
                // else {
                //     const dataOptions = SelectData(list.data);
                //     const dataOptions1 = StaticSelectData(list.name);
                //     // console.log("CompanyList : "+dataOptions);
                //     if(InV != null) {
                //         // console.log(dataOptions)
                //         // console.log("Selected Data: "+list.name+":"+InV);
                //         field = <Form.Item
                //             name={`${list.name}`}
                //             label={<IntlMessages id={`${list.label}`} />}
                //             initialValue={InV}
                //             rules={[
                //                 {
                //                     required: list.required,
                //                     message: list.error_msg
                //                 },
                //             ]}
                //         >
                //             <Select
                //                 showSearch
                //                 placeholder={list.placeholder}
                //                 optionFilterProp="children"
                //                 filterOption={(input, option) => option.props.children?.toString().toLowerCase().indexOf(input?.toLowerCase()) >= 0 || false}
                //             >
                //                 {
                //                     list.isStatic === 1 && (
                //                         dataOptions1.map((items, index) =>
                //                             <Option key={++index} value={items.id} >{items.name}</Option>
                //                         )
                //                     )
                //                 }
                //                 {
                //                     dataOptions.map((items, index) =>
                //                         <Option key={++index} value={items.id} >{items.name}</Option>
                //                     )
                //                 }
                //             </Select>
                //         </Form.Item>
                //     }
                // }


                const dataOptions = SelectData(list.data);
                const dataOptions1 = StaticSelectData(list.name);

                let ds = null;
                if(InV !== "" || InV > 0) {
                    ds = InV;
                }

                if(list.name === 'company_id' || list.name === 'com_id') {

                    const companyID = CisUI().getUserInfo('com_id');
                    if(companyID > 0) {

                    }
                    else {

                        if (InV != null) {
                            field = <Form.Item
                                name={`${list.name}`}
                                label={<IntlMessages id={`${list.label}`} />}
                                initialValue={ds}
                                rules={[
                                    {
                                        required: list.required,
                                        message: list.placeholder
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    placeholder={list.placeholder}
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                >
                                    {
                                        list.isStatic === 1 && (
                                            dataOptions1.map((items, index) =>
                                                <Option key={++index} value={items.id}>{items.name}</Option>
                                            )
                                        )
                                    }
                                    {
                                        dataOptions.map((items, index) =>
                                            <Option key={++index} value={items.id}>{items.name}</Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        }
                    }
                }
                else if(list.name === 'local_id') {
                    const userArea = CisUI().getUserInfo('comarea');
                    if(userArea >= 6) {

                    }
                    else {
                        if (InV != null) {
                            field = <Form.Item
                                name={`${list.name}`}
                                label={<IntlMessages id={`${list.label}`} />}
                                initialValue={InV}
                                rules={[
                                    {
                                        required: list.required,
                                        message: list.placeholder
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    placeholder={list.placeholder}
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                >
                                    {
                                        list.isStatic === 1 && (
                                            dataOptions1.map((items, index) =>
                                                <Option key={++index} value={items.id}>{items.name}</Option>
                                            )
                                        )
                                    }
                                    {
                                        dataOptions.map((items, index) =>
                                            <Option key={++index} value={items.id}>{items.name}</Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        }
                    }
                }
                else if(list.name === 'district_id') {
                    const userArea = CisUI().getUserInfo('comarea');
                    if(userArea >= 5) {

                    }
                    else {
                        if (InV != null) {
                            field = <Form.Item
                                name={`${list.name}`}
                                label={<IntlMessages id={`${list.label}`} />}
                                initialValue={InV}
                                rules={[
                                    {
                                        required: list.required,
                                        message: list.placeholder
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    placeholder={list.placeholder}
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                >
                                    {
                                        list.isStatic === 1 && (
                                            dataOptions1.map((items, index) =>
                                                <Option key={++index} value={items.id}>{items.name}</Option>
                                            )
                                        )
                                    }
                                    {
                                        dataOptions.map((items, index) =>
                                            <Option key={++index} value={items.id}>{items.name}</Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        }
                    }
                }
                else if(list.name === 'region_id') {
                    const userArea = CisUI().getUserInfo('comarea');
                    if(userArea >= 4) {

                    }
                    else {
                        if (InV != null) {
                            field = <Form.Item
                                name={`${list.name}`}
                                label={<IntlMessages id={`${list.label}`} />}
                                initialValue={InV}
                                rules={[
                                    {
                                        required: list.required,
                                        message: list.placeholder
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    placeholder={list.placeholder}
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                >
                                    {
                                        list.isStatic === 1 && (
                                            dataOptions1.map((items, index) =>
                                                <Option key={++index} value={items.id}>{items.name}</Option>
                                            )
                                        )
                                    }
                                    {
                                        dataOptions.map((items, index) =>
                                            <Option key={++index} value={items.id}>{items.name}</Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        }
                    }
                }
                else if(list.name === 'ast_id') {
                    if (InV != null) {
                        field = <Form.Item
                            name={`${list.name}`}
                            label={<IntlMessages id={`${list.label}`}/>}
                            initialValue={InV}
                            rules={[
                                {
                                    required: list.required,
                                    message: list.placeholder
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                placeholder={list.placeholder}
                                optionFilterProp="children"
                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                // defaultValue={`${InV}`}
                                onChange={handleChange}
                            >
                                {
                                    list.isStatic === 1 && (
                                        dataOptions1.map((items, index) =>
                                            <Option key={++index} value={items.id}>{items.name}</Option>
                                        )
                                    )
                                }
                                {
                                    dataOptions.map((items, index) =>
                                        <Option key={++index} value={items.id}>{items.name} ({items.code})</Option>
                                    )
                                }
                            </Select>
                        </Form.Item>
                    }

                }
                else {
                    if (InV != null) {
                        // console.log(dataOptions)
                        // console.log("Selected Data: "+list.name+":"+InV);
                        field = <Form.Item
                            name={`${list.name}`}
                            label={<IntlMessages id={`${list.label}`} />}
                            initialValue={InV}
                            rules={[
                                {
                                    required: list.required,
                                    message: list.placeholder
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                placeholder={list.placeholder}
                                optionFilterProp="children"
                                onChange={onchange}
                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            >
                                {
                                    list.isStatic === 1 && (
                                        dataOptions1.map((items, index) =>
                                            <Option key={++index} value={items.id}>{items.name}</Option>
                                        )
                                    )
                                }
                                {
                                    dataOptions.map((items, index) =>
                                        <Option key={++index} value={items.id}>{items.name}</Option>
                                    )
                                }
                            </Select>
                        </Form.Item>
                    }
                }

            }
            else if(list.type === 'textarea') {
                if(InV !=null) {
                    field = <Form.Item
                        id={`${list.name}`}
                        name={`${list.name}`}
                        label={<IntlMessages id={`${list.label}`} />}
                        initialValue={InV}
                        rules={[
                            {
                                required: list.required,
                                message: list.error_msg
                            },
                        ]}
                    >
                        <TextArea placeholder={list.placeholder} rows={2}/>
                    </Form.Item>
                }
            }
            else if(list.type === 'file') {
                if(InV !=null) {
                    field =
                        <Form.Item
                            name={`${list.name}`}
                            label={<IntlMessages id={`${list.label}`} />}
                            valuePropName="fileList"
                            getValueFromEvent={normFile}
                            extra=""
                            rules={[
                                {
                                    required: list.required,
                                    message: list.error_msg
                                },
                            ]}
                        >
                            <Upload name="logo" action="/upload.do" listType="picture">
                                <Button>
                                    <UploadOutlined/> Click to upload
                                </Button>
                            </Upload>
                        </Form.Item>
                }
            }
            else if(list.type === 'checkbox') {
                let ck = true;
                if(InV === list.value) {
                    ck = true;
                }
                else {
                    ck = false;
                }

                const ckV = values[`${list.name}`];

                field = <Form.Item
                    name={`${list.name}`}
                    label={<IntlMessages id={`${list.label}`} />}
                    // initialValue={`${list.value}`}
                    // defaultChecked={ck}
                    valuePropName="checked"

                >
                    <Checkbox onChange={onchange} >{list.value_label} {list.text}</Checkbox>

                </Form.Item>
            }

            children.push(
                <Col lg={list.size * 2} md={list.size * 2} sm={12} xs={24} key={index}
                     style={{display: index < count ? 'block' : 'none'}}>
                    <div className="gx-form-row0">
                        {field}
                    </div>
                </Col>,
            );
        });
        return children;
    };


    return (
       <>
           <Row gutter={24}>
               {
                   isEdit === 0
                   ?getFields()
                   :getFieldsEdit()
               }
           </Row>
           {/*<Row gutter={24}>*/}
           {/*    <Col lg={24} md={24} sm={12} xs={24} style={{textAlign: 'center'}}>*/}
           {/*        <Button type="primary" htmlType="submit">*/}
           {/*            Submit*/}
           {/*        </Button>*/}
           {/*        <Button type="info" onClick={() => history.goBack()} htmlType="back">Cancel</Button>*/}
           {/*    </Col>*/}
           {/*</Row>*/}
       </>
    );
};

export default InputFields;
