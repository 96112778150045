import React, {useState, useEffect} from "react";
import { Form, Col, Input, Card, Collapse,} from 'antd';
import jsonfile from './loginhistory.json';
import {useHistory} from "react-router-dom";
import {CisUI} from '../../../../util/CISUI';
import AdvancedSearch from '../../../../util/AdvancedSearch';
import SmartDataTable from 'react-smart-data-table';
import 'react-smart-data-table/dist/react-smart-data-table.css';
import IntlMessages from "util/IntlMessages";
import Config from "../../../../util/config";
import axiosinstance from "../../../../util/Api";
import CircularProgress from "../../../../components/CircularProgress";

const Panel = Collapse.Panel;

const LoginHistory = () => {
    const history = useHistory();
    const [state,setState] = useState({
        reqURL: Config.apiserver+jsonfile.urls.list,
        filtervalue : '',
        subtitle:''
    });
    const [loader, setLoader] = useState(false);
    const [apiData, setAPIData] = useState([]);
    useEffect(() => {
        // setState({
        //     reqURL: Config.apiserver+jsonfile.urls.list,
        // });
        setLoader(true)
        axiosinstance.get(state.reqURL)
            .then(res => {
                if(res.data.status === 1){
                    setAPIData(res.data.data)
                    CisUI().createNotification('error', res.data.msg)
                }
                else{
                    CisUI().createNotification('error', res.data.msg)
                }
                setLoader(false)
            })
            .catch(error => {
                console.log(error)
                setLoader(false)
            })
    }, [state.reqURL]);

    const headers = {
        id: {
            text: <IntlMessages id="Sl NO"/>,
            sortable: true,
            filterable: true,
        },
        company: {
            text: <IntlMessages id="Company"/>,
            sortable: true,
            filterable: true,
        },
        user_name: {
            text: <IntlMessages id="Username"/>,
            sortable: true,
            filterable: true,
        },
        login_source: {
            text: <IntlMessages id="Login Source"/>,
            sortable: true,
            filterable: true,
        },
        login_time: {
            text: <IntlMessages id="Login Time"/>,
            sortable: true,
            filterable: true,
            transform: (value) => (
                CisUI().DateFormatWithTime(value)
            )
        },
        hit_time: {
            text: <IntlMessages id="Hit Time"/>,
            sortable: true,
            filterable: true,
            transform: (value) => (
                CisUI().DateFormatWithTime(value)
            )
        },
        ip: {
            text: <IntlMessages id="IP"/>,
            sortable: true,
            filterable: true,
        },
        logout_time: {
            text: <IntlMessages id="Logout Time"/>,
            sortable: true,
            filterable: true,
            transform: (value) => (
                CisUI().DateFormatWithTime(value)
            )
        },
        browser_info: {
            text: <IntlMessages id="Browser Info"/>,
            sortable: true,
            filterable: true,
        },
        status: {
            text: <IntlMessages id="Status"/>,
            sortable: true,
            filterable: true,
            transform: (value, idx, row) => (
                CisUI().getStatusLabel(value)
            )
        }
    };

    const orderedHeaders = jsonfile.listView.title;

    const [form] = Form.useForm();

    const onFinish = values => {
        console.log('Received values of form: ', values);
        const qs = Object.keys(values).map(key => `${key}=${values[key]}`).join('&');
        const qsup = qs.replaceAll("undefined","");
        //reqURL = reqURL+"/?"+qsup;
        history.push("?"+qsup);
        const newURL = Config.apiserver+jsonfile.urls.list+"/?"+qsup;
        setState({reqURL: newURL});
    };

    let  searchView = "";
    if(jsonfile.has_search) {
        searchView = <Card className="advance-search-card">
            <Collapse
                accordion
                expandIconPosition="right"
                defaultActiveKey={['1']} >
                <Panel header={<IntlMessages id="Advance Search"/>}  key="1">
                    <Form
                        key={5}
                        form={form}
                        {...CisUI().formItemLayout}
                        name="advanced_search"
                        className="ant-advanced-search-form"
                        onFinish={onFinish}
                    >
                        {AdvancedSearch(jsonfile.loginSearchInputs)}
                    </Form>
                </Panel>
            </Collapse>
        </Card>
    }

    const getExtra = (
        <>
            {/*{CisUI().listAction("../"+jsonfile.urls.add, "", state.reqURL, "data", 'login_history', jsonfile, state.subtitle, 'landscape')}*/}
            {CisUI().ListActionButtonV2("../"+jsonfile.urls.add, "", state.reqURL, jsonfile)}
        </>
    );

    // if(loader){
    //     return <CircularProgress />;
    // }

    return (
        loader ? <CircularProgress /> :
            <>
            {searchView}
            <Card title={<IntlMessages id={jsonfile.listtitle} />} extra={getExtra}>
                <SmartDataTable
                    name='test-fake-table'
                    data={apiData}
                    dataRequestOptions={CisUI().HeaderRequest}
                    dataKey="data"
                    headers={headers}
                    orderedHeaders={orderedHeaders}
                    hideUnordered={CisUI().hideUnordered}
                    className={CisUI().sematicUI.table}
                    filterValue={state.filtervalue}
                    perPage={CisUI().perPage}
                    sortable
                    withLinks
                    dynamic
                    withHeader
                    // loader={loader ? CisUI().loader : ''}
                    parseBool={{
                        yesWord: 'Yes',
                        noWord: 'No',
                    }}
                    parseImg={{
                        style: {
                            border: '1px solid #ddd',
                            borderRadius: '50px',
                            padding: '3px',
                            width: '30px',
                            height: '30px'
                        },
                    }}
                    emptyTable={CisUI().emptyTable}
                />

            </Card>
        </>
    );
};

export default LoginHistory;