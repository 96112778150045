import React, {useState, useEffect} from "react";
import {Form, Input, Button, Card, Modal, Select, notification} from 'antd';
import {Link} from "react-router-dom";
import jsonfile from './purchase.json';
import {CisUI} from '../../../../util/CISUI';
import 'react-smart-data-table/dist/react-smart-data-table.css'
import Config from "../../../../util/config";
import axios from "../../../../util/Api";
import {useHistory} from "react-router-dom";
import {values} from "lodash/object";

var Barcode = require('react-barcode');
const {Option} = Select;

const ReachableContext = React.createContext();
const UnreachableContext = React.createContext();


const PoItemReceive = (props) => {

    const formRef = React.createRef();

    const [modal, contextHolder] = Modal.useModal();
    const [loading, setLoading] = useState([]);
    const [hasremain, setHasRemain] = useState(true);

    const [state, setState] = useState({
        po: {},
        items: [],
        isPOApprover: 0,
        hasBill: 0,
        totals: {}
    })


    const userID = props.match.params.id;
    let userData = [];
    // console.log("Userid : "+props.match.params.id);
    let isEdit = 0;
    if (userID > 0) {
        isEdit = 1;
    }

    const print = () => {
        return <Link to={"print/" + userID}>Print</Link>
    };

    const onFinish = (values) => {
        // setLoading({loading: true})
        values["items"] = state.items;
        values["oid"] = userID;
        // console.log(JSON.stringify(values));
        document.getElementById("loader").style.display = "block";
        axios.post(Config.apiserver + endpoint, values)
            .then(res => {
                console.log(res)
                if (res.data.status === 1) {
                    notification.success({
                        message: 'Success',
                        type: "success",
                        description: res.data.msg
                    });
                    history.push(redirectto);
                } else {
                    notification.warning({
                        message: 'Alert',
                        type: "warning",
                        description: res.data.msg
                    });
                }
                document.getElementById("loader").style.display = "none";
            })
            .catch(errors => {
                console.log(errors);
            });
    };

    let Title = "Item Received Details";
    let endpoint = "purchase/additemreceive";
    let redirectto = "../" + jsonfile.urls.list;

    if (isEdit === 1) {

        Title = "Item Received Details";
        endpoint = "purchase/additemreceive/" + userID;
        redirectto = "../../" + jsonfile.urls.list;
    }

    useEffect(() => {
        getPODetails();
    }, []);

    const getPODetails = () => {
        axios.get(Config.apiserver + jsonfile.urls.view + "/" + userID)
            .then((res) => {
                //console.log(res.data);
                if (res.data.status === 1) {
                    let row = [];
                    let hasremains = false;
                    let totalQty = 0;
                    let totalRemain = 0;
                    res.data.items.map((items,index) => {
                        const remain  = (parseFloat(items.quantity) - (parseFloat(items.rcv_quantity) + parseFloat(items.return_quantity) + parseFloat(items.damaged_quantity)))
                        totalRemain += remain;
                        row.push({
                            id : items.id,
                            item_id : items.item_id,
                            code: items.code,
                            name : items.name,
                            quantity : items.quantity,
                            price : items.price,
                            has_stock : items.has_stock,
                            rcv_quantity : items.rcv_quantity,
                            return_quantity: items.return_quantity,
                            damaged_quantity: items.damaged_quantity,
                            nrcv: remain,
                            ndamage: 0,
                            nremain : 0
                        })
                    });

                    if(totalRemain > 0) {
                        setHasRemain(true);
                    }
                    else {
                        setHasRemain(false);
                    }

                    setState({
                        po: res.data.podata,
                        items: row,
                        isPOApprover: res.data.isPOApprover,
                        hasBill: res.data.hasBill,
                        totals: {}
                    })
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const [form] = Form.useForm();
    const history = useHistory();

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
    const [isRejectModalVisible, setIsRejectModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const showModalCofirm = () => {
        setIsConfirmModalVisible(true);
    };

    const handleOkConfirm = () => {
        setIsConfirmModalVisible(false);
        PoStatusUpdate(state.po.id,1);
    };

    const handleCancelConfirm = () => {
        setIsConfirmModalVisible(false);
    };

    const showModalReject = () => {
        setIsRejectModalVisible(true);
    };

    const handleOkReject = () => {
        setIsRejectModalVisible(false);
        PoStatusUpdate(state.po.id,3);
    };

    const handleCancelReject = () => {
        setIsRejectModalVisible(false);
    };

    const handleChange = () => {

    }



    const PoStatusUpdate = (id,status) => {
        document.getElementById("loader").style.display = "block";
        axios.get("purchase/postatuschange?po_id="+id+"&status="+status)
            .then((res) => {
                document.getElementById("loader").style.display = "none";
                console.log(res.data);
                if(res.data.status === 1) {
                    notification.success({
                        message: 'Success',
                        type: "success",
                        description: res.data.msg
                    });
                    getPODetails();
                }
                else {
                    document.getElementById("loader").style.display = "none";
                    notification.success({
                        message: 'Failed',
                        type: "danger",
                        description: res.data.msg
                    });
                }
            })
            .catch((error) => {
                console.log(error);
                document.getElementById("loader").style.display = "none";
            });
    }

    var totalAmount = 0;

    const config = {
        title: 'Alert',
        content: (
            <>
                <ReachableContext.Consumer>{name => `Reachable: ${name}!`}</ReachableContext.Consumer>
                <br />
                <UnreachableContext.Consumer>{name => `Unreachable: ${name}!`}</UnreachableContext.Consumer>
            </>
        ),
    };

    const ItemCellChange = idx => e => {
        const { name, value } = e.target;
        const rows = [...state.items];
        console.log("selectedITems:"+ JSON.stringify(rows[idx]));
        if(name === "qty") {
            const amount = value * rows[idx].price;
            const remain = (parseFloat(rows[idx].quantity) - (parseFloat(rows[idx].rcv_quantity) + rows[idx].damaged_quantity + parseFloat(value) + parseFloat(rows[idx].ndamage)));
            rows[idx] = {
                id : rows[idx].id,
                item_id : rows[idx].item_id,
                code: rows[idx].code,
                name : rows[idx].name,
                quantity : rows[idx].quantity,
                price : rows[idx].price,
                has_stock : rows[idx].has_stock,
                rcv_quantity : rows[idx].rcv_quantity,
                return_quantity: rows[idx].return_quantity,
                damaged_quantity: rows[idx].damaged_quantity,
                nrcv: value || 0,
                ndamage: rows[idx].ndamage || 0,
                nremain : remain || 0
            };
        }
        else if(name === "quantity") {
            const amount = value * rows[idx].price;
            const remain = (parseFloat(rows[idx].quantity) - (parseFloat(rows[idx].rcv_quantity) + rows[idx].damaged_quantity + parseFloat(value) + parseFloat(rows[idx].ndamage)));
            rows[idx] = {
                id : rows[idx].id,
                item_id : rows[idx].item_id,
                code: rows[idx].code,
                name : rows[idx].name,
                quantity : rows[idx].quantity,
                price : rows[idx].price,
                has_stock : rows[idx].has_stock,
                rcv_quantity : rows[idx].rcv_quantity,
                return_quantity: rows[idx].return_quantity,
                damaged_quantity: rows[idx].damaged_quantity,
                nrcv: value || null,
                ndamage: rows[idx].ndamage || 0,
                nremain : remain || 0
            };
        }
        else if(name === "damage") {
            //CalculateCell();
            const remain = (parseFloat(rows[idx].quantity) - (parseFloat(rows[idx].rcv_quantity) + rows[idx].damaged_quantity + parseFloat(rows[idx].nrcv) + parseFloat(value)));
            rows[idx] = {
                id : rows[idx].id,
                item_id : rows[idx].item_id,
                code: rows[idx].code,
                name : rows[idx].name,
                quantity : rows[idx].quantity,
                price : rows[idx].price,
                has_stock : rows[idx].has_stock,
                rcv_quantity : rows[idx].rcv_quantity,
                return_quantity: rows[idx].return_quantity,
                damaged_quantity: rows[idx].damaged_quantity,
                nrcv: rows[idx].nrcv || 0,
                ndamage: value || null,
                nremain : remain || 0
            };

        }

        let totalRcv = 0
        let totalDamaged = 0
        rows.map((item, idx) => {
            totalRcv += item.nrcv;
            totalDamaged += item.ndamage;
        });

        setState({
            po: state.po,
            items: rows,
            isPOApprover: state.isPOApprover,
            hasBill: state.hasBill,
            totals: {
                totalRcv : totalRcv,
                totalDamaged : totalDamaged
            }
        });

    };

    return (
        <>
            <Card title={Title}>
                {CisUI().showLoading}
                {
                    isEdit === 1
                        ? CisUI().addAction('../../' + jsonfile.urls.list)
                        : CisUI().addAction('../' + jsonfile.urls.list)
                }

                <Form
                    form={form}
                    {...CisUI().formItemLayout}
                    name="input"
                    ref={formRef}
                    className="ant-advanced-search-form"
                    onFinish={onFinish}>

                    <div className="row">
                        <div className="col-md-12" style={{textAlign: 'center'}}>
                            <div style={{width: '100%', background: 'white', border: '1px #ddd solid', padding: '20px'}}>
                                <table style={{width: '100%'}}>
                                    <tr>
                                        <td style={{width: '50%', textAlign: 'left'}}>
                                            <img alt="cop" style={{width: '100px'}} src={Config.print_logourl}/>
                                            {/*<div>{Config.address}</div>*/}
                                            {/*<div>{Config.city},{Config.state},{Config.zipcode}</div>*/}
                                            {/*<div>Phone : {Config.phone}</div>*/}
                                            {/*<div>Email : {Config.email}</div>*/}
                                            {/*<div>Website : {Config.website}</div>*/}
                                        </td>
                                        <td style={{width: '50%', textAlign: 'right'}}>
                                            <h2>PURCHASE ORDER</h2>
                                            <div>Date : {CisUI().DateFormat(state.po.date)}</div>
                                            <div>PO Number : {state.po.po_number}</div>
                                            <div><Barcode height={50} value={state.po.po_number}/></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{width: '50%', textAlign: 'left', paddingTop: '20px'}}>
                                            <h3>Ship From : </h3>
                                            <h5>{state.po.vendor_name}</h5>
                                            <div>{state.po.vendor_address}</div>
                                            <div>{state.po.vendor_city}, {state.po.vendor_state}, {state.po.vendor_zipcode}</div>
                                            <div>Contact Person : {state.po.vendor_contact}</div>
                                            <div>Phone : {state.po.vendor_phone}</div>
                                            <div>Email : {state.po.vendor_phone}</div>
                                        </td>
                                        <td style={{width: '50%', textAlign: 'left', paddingTop: '20px'}}>
                                            <div style={{float: "right", width: '300px'}}>
                                                <h3>Ship To : </h3>
                                                <h5>{state.po.company}</h5>
                                                <div>{state.po.com_address}</div>
                                                <div>{state.po.com_city}, {state.po.com_state}, {state.po.com_zipcode}</div>
                                                <div>Phone : {state.po.company_phone}</div>
                                                <div>Email : {state.po.company_phone}</div>
                                            </div>
                                        </td>
                                    </tr>
                                </table>

                                <table className="ptable table table-bordered" style={{marginTop: '30px'}} width="100%">
                                    <thead>
                                    <tr>
                                        <th>Required By</th>
                                        <th>Shipping Method</th>
                                        <th>Payment Terms</th>
                                        <th>Discount(%)</th>
                                        <th>Sales Tax(%)</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>{CisUI().DateFormat(state.po.required_by_date)}</td>
                                        <td>{state.po.shipping_method}</td>
                                        <td>{state.po.payment_terms}</td>
                                        <td>{state.po.discount_p}%</td>
                                        <td>{state.po.sales_tax_p}%</td>
                                    </tr>
                                    </tbody>
                                </table>

                                <table id="itemtable" style={{marginTop: '30px'}} className="ptable table table-bordered">
                                    <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Item Code</th>
                                        <th>Item Description</th>
                                        <th>Quantity Ordered</th>
                                        <th>Received</th>
                                        <th>Damaged</th>
                                        <th>Receive Quantity</th>
                                        <th>Damaged Quantity</th>
                                        <th>Remain</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {state.items.map((item, idx) => (
                                        <tr id="addr0" key={idx}>
                                            <td style={{width: '30px'}}>{(idx + 1)}</td>
                                            <td style={{width: '150px'}}>{item.code}</td>
                                            <td>{item.name}</td>
                                            <td style={{width: '70px', textAlign: 'right'}} >{item.quantity}</td>
                                            <td style={{width: '70px', textAlign: 'right'}} >{item.rcv_quantity}</td>
                                            <td style={{width: '70px', textAlign: 'right'}} >{item.damaged_quantity}</td>
                                            <td style={{
                                                width: '70px',
                                                textAlign: 'right'
                                            }}>
                                                {
                                                    item.quantity === (item.rcv_quantity + item.damaged_quantity) ? 0 :
                                                        <input type="number" className="form-control"
                                                               onChange={ItemCellChange(idx)} name="quantity"
                                                               value={item.nrcv || null}/>
                                                }
                                            </td>
                                            <td style={{
                                                width: '70px',
                                                textAlign: 'right'
                                            }}>
                                                {
                                                    item.quantity === (item.rcv_quantity + item.damaged_quantity) ? item.damaged_quantity :
                                                        <input type="number" className="form-control"
                                                               onChange={ItemCellChange(idx)} name="damage"
                                                               value={item.ndamage || null}/>
                                                }
                                            </td>
                                            <td style={{width: '70px', textAlign: 'right'}}>{item.nremain}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                    <tfoot style={{display: 'none'}}>
                                    <tr>
                                        <td colSpan={3} rowSpan={7} style={{verticalAlign: "top"}}>
                                            <div style={{
                                                background: '#eaf0f3',
                                                marginTop: '20px',
                                                padding: '3px',
                                                textTransform: 'uppercase',
                                                borderBottom: '1px solid #ddd'
                                            }}>Comments or Special Remark
                                            </div>
                                            <textarea rows={5} className="form-control"></textarea>
                                        </td>
                                        <td style={{textAlign: 'right', fontWeight: 'bold'}} >Total</td>
                                        <td style={{
                                            textAlign: 'right',
                                            fontWeight: 'bold'
                                        }}>0</td>
                                        <td style={{
                                            textAlign: 'right',
                                            fontWeight: 'bold'
                                        }}>0</td>
                                        <td style={{
                                            textAlign: 'right',
                                            fontWeight: 'bold'
                                        }}>0</td>
                                        <td style={{
                                            textAlign: 'right',
                                            fontWeight: 'bold'
                                        }}>0</td>
                                    </tr>
                                    </tfoot>
                                </table>

                            </div>
                        </div>
                    </div>

                    {
                        hasremain ?
                            <div className="row no-print">
                                <div className="col-md-12" style={{textAlign: 'center', marginTop: '30px'}}>
                                    <Button id="submitbutton" type="primary" htmlType="submit">Confirm Receive</Button>
                                    <a className="ant-btn ant-alert-info" onClick={() => history.goBack()}>Cancel</a>
                                </div>
                            </div> : ""
                    }

                </Form>


            </Card>
            <Modal title="Send To Anyone" visible={isModalVisible} okText={"Send"} onOk={handleOk} onCancel={handleCancel}>
                <Form
                    form={form}
                    {...CisUI().formItemLayout}
                    name="input"
                    ref={formRef}
                    className="ant-advanced-search-form"
                    onFinish={onFinish}>

                    <div className="row">
                        <div className="col-md-12">
                            <Form.Item
                                label="Email Address"
                                name="email"
                                onChange={handleChange}
                                rules={[
                                    {
                                        required: true,
                                        message: "Enter email addres, separate by comma for multiple email "
                                    },
                                ]}
                            >
                                <Input placeholder="Enter email address" />
                            </Form.Item>
                        </div>
                    </div>

                </Form>
            </Modal>

            <Modal title="Alert" visible={isConfirmModalVisible} okText={"Confirm"} onOk={handleOkConfirm} onCancel={handleCancelConfirm}>
                <h4>Are you sure you want to approve ?</h4>
            </Modal>

            <Modal title="Alert" visible={isRejectModalVisible} okText={"Confirm"} onOk={handleOkReject} onCancel={handleCancelReject}>
                <h4>Are you sure you want to reject ?</h4>
            </Modal>

        </>
    );
};

export default PoItemReceive;