import React, {useState, useEffect} from "react";
import { Form, Col, Input, Collapse ,Card} from 'antd';
import jsonfile from './budget.json'
import {useHistory, useRouteMatch} from "react-router-dom";
import {CisUI} from '../../../../util/CISUI';
import AdvancedSearch from '../../../../util/AdvancedSearch';
import SmartDataTable from 'react-smart-data-table';
import 'react-smart-data-table/dist/react-smart-data-table.css';
import IntlMessages from "util/IntlMessages";
import Config from "../../../../util/config";
import ActionButton from "../../../../util/actionbutton";

const Panel = Collapse.Panel;

const BudgetHistory = () => {
    const value = 0;
    const [state,setState] = useState({
        reqURL: Config.apiserver+jsonfile.urls.list,
        filtervalue : '',
        subtitle : '',
    });

    useEffect(() => {
        setState(
            {
                reqURL: Config.apiserver+jsonfile.urls.list
            }
        );
    }, []);

    const history = useHistory();

    const headers = {
        // _id: {
        //     text: '#',
        //     invisible: false,
        //     filterable: false,
        //     transform: (value) => `${++value}`,
        // },
        id: {
            invisible : true
        },
        department_id: {
            invisible: true
        },
        date: {
            text: <IntlMessages id="Date"/>,
            sortable: true,
            filterable: true,
            transform: (value, idx, row) => (
                CisUI().DateFormat(value)
            )
        },
        month1: {
            text: <IntlMessages id="Jan"/>,
            sortable: true,
            filterable: true,
            transform: (value, idx, row) => (
                CisUI().getCurrency(value)
            )
        },
        month2: {
            text: <IntlMessages id="Feb"/>,
            sortable: true,
            filterable: true,
            transform: (value, idx, row) => (
                CisUI().getCurrency(value)
            )
        },
        month3: {
            text: <IntlMessages id="Mar"/>,
            sortable: true,
            filterable: true,
            transform: (value, idx, row) => (
                CisUI().getCurrency(value)
            )
        },
        month4: {
            text: <IntlMessages id="Apr"/>,
            sortable: true,
            filterable: true,
            transform: (value, idx, row) => (
                CisUI().getCurrency(value)
            )
        },
        month5: {
            text: <IntlMessages id="May"/>,
            sortable: true,
            filterable: true,
            transform: (value, idx, row) => (
                CisUI().getCurrency(value)
            )
        },
        month6: {
            text: <IntlMessages id="Jun"/>,
            sortable: true,
            filterable: true,
            transform: (value, idx, row) => (
                CisUI().getCurrency(value)
            )
        },
        month7: {
            text: <IntlMessages id="Jul"/>,
            sortable: true,
            filterable: true,
            transform: (value, idx, row) => (
                CisUI().getCurrency(value)
            )
        },
        month8: {
            text: <IntlMessages id="Aug"/>,
            sortable: true,
            filterable: true,
            transform: (value, idx, row) => (
                CisUI().getCurrency(value)
            )
        },
        month9: {
            text: <IntlMessages id="Sep"/>,
            sortable: true,
            filterable: true,
            transform: (value, idx, row) => (
                CisUI().getCurrency(value)
            )
        },
        month10: {
            text: <IntlMessages id="Oct"/>,
            sortable: true,
            filterable: true,
            transform: (value, idx, row) => (
                CisUI().getCurrency(value)
            )
        },
        month11: {
            text: <IntlMessages id="Nov"/>,
            sortable: true,
            filterable: true,
            transform: (value, idx, row) => (
                CisUI().getCurrency(value)
            )
        },
        month12: {
            text: <IntlMessages id="Dec"/>,
            sortable: true,
            filterable: true,
            transform: (value, idx, row) => (
                CisUI().getCurrency(value)
            )
        },
        total: {
            text: <IntlMessages id="Total"/>,
            sortable: true,
            filterable: true,
            transform: (value, idx, row) => (
                CisUI().getCurrency(value)
            )
        },
        created_at: {
            text: <IntlMessages id="Created"/>,
            sortable: true,
            filterable: true,
            transform: (value, idx, row) => (
                CisUI().DateFormat(value)
            )
        },
        updated_at: {
            text: <IntlMessages id="Updated"/>,
            sortable: true,
            filterable: true,
            transform: (value, idx, row) => (
                CisUI().DateFormat(value)
            )
        },
        actions: {
            text: 'Actions',
            sortable: false,
            filterable: false,
            transform: (value, idx, row) => (
                <>
                    {ActionButton(row.id,jsonfile.urls,jsonfile.urls.list)}
                </>
            ),
        },
        com_id : { invisible: true},
        account_id : { invisible: true},
        account_number : { invisible: true},
        account_name : { invisible: true},
        remark : { invisible: true},
        act_type : { invisible: true},
        created_by : { invisible: true},
        updated_by : { invisible: true},
        approved_at : { invisible: true},
        approved_by : { invisible: true},
        status : { invisible: true},
    };

    const orderedHeaders = jsonfile.listView.title;

    const getFields = () => {
        const count = expand ? 10 : 6;
        const children = [];
        const inputs = jsonfile.input;

        inputs.map((list, index) => {
            if(list.isFilterable == 1) {
                children.push(
                    <Col lg={6} md={6} sm={12} xs={24} key={index} style={{display: index < count ? 'block' : 'none'}}>
                        <div className="gx-form-row0">
                            <Form.Item
                                name={`${list.name}`}
                                label={`${list.label}`}
                                rules={[
                                    {
                                        required: true,
                                        message: "",
                                    },
                                ]}
                            >
                                <Input placeholder={list.name} />
                            </Form.Item>
                        </div>
                    </Col>,
                );
            }
        });
        return children;
    };

    const [expand, setExpand] = useState(false);
    const [form] = Form.useForm();

    const onFinish = values => {
        console.log('Received values of form: ', values);
        const qsup = CisUI().getQueryStrings(values);
        //reqURL = reqURL+"/?"+qsup;
        history.push("?"+qsup);
        const newURL = Config.apiserver+jsonfile.urls.list+"?type=2&"+qsup;
        setState({reqURL: newURL});
    };

    let  searchView = "";
    if(jsonfile.has_search) {
        searchView = <Card className="advance-search-card">
            <Collapse
                accordion
                expandIconPosition="right"
                defaultActiveKey={['1']} >
                <Panel header="Advance Search"  key="1">
                    <Form
                        key={5}
                        form={form}
                        {...CisUI().formItemLayout}
                        name="advanced_search"
                        className="ant-advanced-search-form"
                        onFinish={onFinish}
                    >
                        {AdvancedSearch(jsonfile.input,"",1)}
                    </Form>
                </Panel>
            </Collapse>
        </Card>
    }

    const getExtra = (
        <>
            {CisUI().ListActionButtonV2("../"+jsonfile.urls.add,"",state.reqURL,jsonfile)}
        </>
    );

    return (
        <>
            {searchView}
            <Card title={jsonfile.listtitle} extra={getExtra}>
                <SmartDataTable
                    name='test-fake-table'
                    data={state.reqURL}
                    dataRequestOptions={CisUI().HeaderRequest}
                    dataKey="data"
                    headers={headers}
                    orderedHeaders={orderedHeaders}
                    hideUnordered={CisUI().hideUnordered}
                    className={CisUI().sematicUI.table}
                    filterValue={state.filterValue}
                    perPage={CisUI().perPage}
                    sortable
                    withLinks
                    withHeader
                    loader={CisUI().loader}
                    parseBool={{
                        yesWord: 'Yes',
                        noWord: 'No',
                    }}
                    parseImg={{
                        style: {
                            border: '1px solid #ddd',
                            borderRadius: '50px',
                            padding: '3px',
                            width: '30px',
                            height: '30px'
                        },
                    }}
                    emptyTable={CisUI().emptyTable}
                />
            </Card>
        </>
    );
};

export default BudgetHistory;