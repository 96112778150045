import React, {useState} from "react";
import {Form, Card, Upload, Button, Row, notification, Col} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import jsonfile from './asset_info.json';
import {CisUI} from '../../../../util/CISUI';
import 'react-smart-data-table/dist/react-smart-data-table.css';
import {useHistory} from "react-router-dom";
import RemoteJson from "../../../../util/gerremotedata";
import InputFieldV2 from "../../../../util/InputFieldV2";
import Config from "../../../../util/config";
import axios from "../../../../util/Api";
import InputField from "../../../../util/InputField";

const NewAssetInfo = (props) => {

    const [img, setImg] = useState([]);

    const normFile = e => {
        console.log('Upload event:', e);

        if (Array.isArray(e)) {
            return e;
        }

        return e && e.fileList;
    };

    const handleFile = (e) => {
        let file = e.target.files[0];
        console.log(file);
        let reader = new FileReader();
        reader.onload = (e)=> {
            setImg({
                ...img,
                warranty_attachment : e.target.result
            });
        };
        reader.readAsDataURL(file);
    }

    const FieldChange = (e) => {
        let ast_type = form.getFieldValue('ast_type');
        if(ast_type > 0){
            axios.get(Config.apiserver + 'assets/assettypeopen/'+ast_type, CisUI().HeaderRequest)
                .then(res => {
                    if(res.data.status === 1){
                        form.setFieldsValue({
                            code: res.data.data.prefix + "-"+ res.data.data.asset_code
                        })
                    }
                })
                .catch(errors => console.log(errors))
        }
    }

    const userID = props.match.params.id;
    let userData = [];
    // console.log("Userid : "+props.match.params.id);
    let isEdit = 0;
    if(userID > 0) {
        isEdit = 1;
    }
    let Title = jsonfile.addtitle;
    let endpoint = jsonfile.urls.add;
    let redirectto = "../"+jsonfile.urls.list;

    if(isEdit === 1 ) {
        userData = RemoteJson(jsonfile.urls.view+"/"+userID);
        Title = jsonfile.edittitle;
        endpoint = jsonfile.urls.edit+"/"+userID;
        redirectto = "../../"+jsonfile.urls.list;
    }


    const [form] = Form.useForm();
    const history = useHistory();

    const onFinish = (values) => {
        const data = {
            ...values,
            'purchase_date' : values['purchase_date'].format("YYYY-MM-DD"),
            'maturity' : values['maturity'].format("YYYY-MM-DD")
        }

        document.getElementById("loader").style.display = "block";
        axios.post(Config.apiserver + endpoint, data, CisUI().HeaderRequest)
            .then(res => {
                console.log(res)
                if(res.data.status === 1) {
                    notification.success({
                        message: 'Success',
                        type : "success",
                        description: res.data.msg
                    });
                    history.push(redirectto);
                }
                else {
                    notification.warning({
                        message: 'Alert',
                        type : "warning",
                        description: res.data.msg
                    });
                }
                document.getElementById("loader").style.display = "none";
            })
            .catch(errors => {
                console.log(errors);
            });
    };

    return (
        <Card title={Title} extra={
                isEdit === 1
                    ? CisUI().addAction('../../'+jsonfile.urls.list)
                    : CisUI().addAction('../'+jsonfile.urls.list)
            }>
            {CisUI().showLoading}
            <Form
                form={form}
                {...CisUI().formItemLayout}
                name="input"
                className="ant-advanced-search-form"
                onFinish={onFinish}
                onFieldsChange={FieldChange}
                initialValues={{ remember: true }}
            >
                {/*{InputFieldV2(jsonfile.input,userData,isEdit,props)}*/}
                {InputField(jsonfile.input,userData,isEdit,props)}

                {/*<Form.Item*/}
                {/*    name="warranty_attachment"*/}
                {/*    label="Warranty Attachment"*/}
                {/*    getValueFromEvent={normFile}>*/}
                {/*    <Upload {...props}>*/}
                {/*        <Button icon={<UploadOutlined />}>Select File</Button>*/}
                {/*    </Upload>*/}
                {/*</Form.Item>*/}

                {/*<Row gutter={24}>*/}
                {/*    <Col lg={24} md={24} sm={12} xs={24} style={{textAlign: 'center'}}>*/}
                {/*        <Button type="primary" htmlType="submit">*/}
                {/*            Submit*/}
                {/*        </Button>*/}
                {/*        <Button type="info" onClick={() => history.goBack()} htmlType="back">Cancel</Button>*/}
                {/*    </Col>*/}
                {/*</Row>*/}

            </Form>
        </Card>
    );
};
export default NewAssetInfo;