import React, {useState, useEffect} from "react";
import {Form, Input, Button, Card, Select, DatePicker, notification, Row, Col, InputNumber, message} from 'antd';
import jsonfile from './sales.json'
import {CisUI} from '../../../../util/CISUI'
import 'react-smart-data-table/dist/react-smart-data-table.css'
import Config from "../../../../util/config";
import axios from "../../../../util/Api";
import {useHistory} from "react-router-dom";
import SelectData from "../../../../util/selectdata";
import moment from "moment";
import StaticSelectData from "../../../../util/StaticSelectData";
import CircularProgress from "../../../../components/CircularProgress";

const {Option} = Select;

const EditCorporateSales = (props) => {
	let userID = props.match.params.id;
	const [form] = Form.useForm();
	const [discountPercentage, setDiscountPercentage] = useState(0);
	const [taxPercentage, setTaxPercentage] = useState(0);
	const [state, setState] = useState([
		{
			id: 0,
			item_id: undefined,
			name: undefined,
			code: undefined,
			current_stock: undefined,
			qty: undefined,
			oldQty: undefined,
			price: undefined,
			amount: undefined
		}
	])
	const [product, setProduct] = useState([]);
	const [beneficiary, setBeneficiary] = useState({
		'customer': [],
		'vendor': [],
		'employee': [],
		'hqdepartment': [],
		'area': [],
		'district': [],
		'local': [],
		'staff': [],
		'ministries': [],
		'pentsos': [],
		'committee': [],
		'subsectors': [],
	});
	const [selectedBeneficiary, setSelectedBeneficiary] = useState([]);
	const [editData, setEditData] = useState({});
	const history = useHistory();
	const [loader, setLoader] = useState(false);
	const [bill, setBill] = useState(
		{
			total: 0,
			grand_total: 0,
			freight: 0,
			tax_amount: 0,
			discount_amount: 0
		}
	);

	const handleAddRow = () => {
		let item = {
			id: 0,
			item_id: undefined,
			name: undefined,
			code: undefined,
			current_stock: undefined,
			qty: 0,
			oldQty: 0,
			price: 0.00,
			amount: 0.00
		};
		setState([...state, item])
	}

	const getBeneficiaryData = () => {
		axios.get(Config.apiserver + "beneficiarylist")
			.then((res) => {
				if (res.data.status === 1) {
					const data = res.data.data;
					console.log("edit", data)
					setBeneficiary(data);
				}
			})
			.catch((error) => {
				console.log(error);

			});
	}

	useEffect(() => {
		getBeneficiaryData();
	}, []);

	const OrderDetails = () => {
		setLoader(true)
		axios.get("entries/salesoderdetails/" + userID)
			.then((res) => {
				if (res.data.status === 1) {
					console.log("EditData", res.data)
					// setEditData(res?.data?.data)
					let editData = res.data.data;
					let ben = benf(res.data.data.beneficiary_source)
					console.log("editData1", ben)
					setSelectedBeneficiary(ben);
					setEditData(editData)
				}
				setLoader(false)
			})
			.catch((error) => {
				console.log(error);
			});
	}

	useEffect(() => {
		if (userID > 0) {
			OrderDetails()
			const comID = CisUI().getUserInfo('com_id') || 0;
			if (CisUI().getUserInfo('com_id') > 0) {
				onCompanyChangeLocal(comID);
			}
		}
	}, [beneficiary]);

	useEffect(() => {
		if (editData) {
			form.setFieldsValue({
				...editData,
				// beneficiary_id: selectedBeneficiary,
				discount_pp: editData?.discount_p,
				sales_tax_pp: editData?.sales_tax_p,
				order_date: moment(editData?.order_date),
				due_date: moment(editData?.due_date)
			});
			setDiscountPercentage(editData?.discount_p);
			setTaxPercentage((editData?.sales_tax_p))
			let item_data = []
			editData?.items?.map((item, index) => {
				let products = product.filter((itm, ind) => itm.id === item.item_id)
				item_data.push({
					id: item.id,
					item_id: item.item_id,
					name: products[0]?.name,
					code: products[0]?.sku,
					current_stock: products[0]?.onhand,
					qty: item.quantity,
					oldQty: item.quantity,
					price: item.price,
					amount: item.total
				})
			})
			setState(item_data)

			setBill({
				total: parseFloat(editData?.total),
				grand_total: parseFloat(editData?.grand_total),
				freight: 0,
				taxp: 0,
				tax_amount: parseFloat(editData?.sales_tax),
				discount_amount: parseFloat(editData?.discount)
			})
		}
	}, [editData])

	const onCompanyChangeLocal = value => {
		if (value > 0) {
			let company = {};
			axios.get("customer/customerinfobycompany?com_id=" + value)
				.then((res) => {
					console.log(res.data);
					if (res.data.status === 1) {
						// setCustomer(res.data.data);
						company = res.data.company;
						setProduct(res.data.product);
					}
				})
				.catch((error) => {
					console.log(error);
				});
		}
	}

	const onFinish = (values) => {
		values["items"] = state;
		values["summary"] = bill;
		console.log(values)
		document.getElementById("loader").style.display = "block";
		axios.post(Config.apiserver + 'entries/updatesale/'+ userID, values)
			.then(res => {
				console.log(res)
				if (res.data.status === 1) {
					notification.success({
						message: 'Success',
						type: "success",
						description: res.data.msg
					});

					const oid = res.data.oid;
					const url = "../arap/salesdetails/"+oid;
					const type = values['sales_type'];
					history.push("../../sales/salesorder");

				} else {
					notification.warning({
						message: 'Alert',
						type: "warning",
						description: res.data.msg
					});
				}
				document.getElementById("loader").style.display = "none";
			})
			.catch(errors => {
				console.log(errors);
			});
	}

	let Beneficiary = [];
	const ShippingMethod = SelectData("shippingmethod");
	const SaleType = StaticSelectData("sale_type");
	const BeneficiaryType = SelectData("accountbenificary");
	const Local = SelectData("alllocal");
	const IncomeAccount = SelectData("incomehead");
	const AccountReceiveAble = SelectData("accountsreceiveable");


	const onBeneficiaryChange = (e) => {
		if (e === 2) {
			setSelectedBeneficiary(beneficiary.customer);
		} else if (e === 3) {
			setSelectedBeneficiary(beneficiary.vendor);
		} else if (e === 4) {
			setSelectedBeneficiary(beneficiary.employee);
		} else if (e === 6) {
			setSelectedBeneficiary(beneficiary.hqdepartment);
		} else if (e === 7) {
			setSelectedBeneficiary(beneficiary.area);
		} else if (e === 8) {
			setSelectedBeneficiary(beneficiary.employee);
		} else if (e === 9) {
			setSelectedBeneficiary(beneficiary.employee);
		} else if (e === 10) {
			setSelectedBeneficiary(beneficiary.ministries);
		} else if (e === 11) {
			setSelectedBeneficiary(beneficiary.pentsos);
		} else if (e === 12) {
			setSelectedBeneficiary(beneficiary.district);
		} else if (e === 13) {
			setSelectedBeneficiary(Local);
		} else if (e === 14) {
			setSelectedBeneficiary(beneficiary.committee);
		} else if (e === 15) {
			setSelectedBeneficiary(beneficiary.subsectors);
		}

		form.setFieldsValue({
			beneficiary_id: null
		});
	}

	const benf = (e) => {
		let ben = [];
		if (e === 2) {
			Beneficiary = beneficiary.customer;
			ben = beneficiary.customer;
		} else if (e === 3) {
			Beneficiary = beneficiary.vendor;
			ben = beneficiary.vendor;
		} else if (e === 4) {
			Beneficiary = beneficiary.employee;
			ben = beneficiary.employee;
		} else if (e === 6) {
			Beneficiary = beneficiary.hqdepartment;
			ben = beneficiary.hqdepartment;
		} else if (e === 7) {
			Beneficiary = beneficiary.area;
			ben = beneficiary.area;
		} else if (e === 8) {
			Beneficiary = beneficiary.employee;
			ben = beneficiary.employee;
		} else if (e === 9) {
			Beneficiary = beneficiary.employee;
			ben = beneficiary.employee;
		} else if (e === 10) {
			Beneficiary = beneficiary.ministries;
			ben = beneficiary.ministries;
		} else if (e === 11) {
			Beneficiary = beneficiary.pentsos;
			ben = beneficiary.pentsos;
		} else if (e === 12) {
			Beneficiary = beneficiary.district;
			ben = beneficiary.district;
		} else if (e === 13) {
			Beneficiary = Local;
			ben = Local;
		} else if (e === 14) {
			Beneficiary = beneficiary.committee;
			ben = beneficiary.committee;
		} else if (e === 15) {
			Beneficiary = beneficiary.subsectors;
			ben = beneficiary.subsectors;
		}
		return ben;
	}

	const onDiscountChange = (e) => {
		setDiscountPercentage(e.target.value)
		let discount_p = parseFloat(e.target.value) || 0;
		let total = bill.total;
		let freight = bill.freight;
		let tax_p = taxPercentage;
		let discount_amount = parseFloat(total) * discount_p / 100;
		let totalBeforeTax = parseFloat(total) - parseFloat(discount_amount);
		let taxAmount = parseFloat(totalBeforeTax) * (parseFloat(tax_p) / 100);
		let grandTotal = totalBeforeTax + taxAmount + +parseFloat(freight);

		setBill({
			total: total,
			grand_total: grandTotal,
			freight: freight,
			tax_amount: taxAmount,
			discount_amount: discount_amount
		});
	}

	const onTaxChange = e => {
		setTaxPercentage(e.target.value)
		let tax_p = parseFloat(e.target.value) || 0;
		let total = bill.total;
		let freight = bill.freight;
		let discount_p = discountPercentage;
		let discount_amount = parseFloat(total) * discount_p / 100;
		let totalBeforeTax = parseFloat(total) - parseFloat(discount_amount);
		let taxAmount = parseFloat(totalBeforeTax) * (parseFloat(tax_p) / 100);
		let grandTotal = parseFloat(totalBeforeTax) + parseFloat(taxAmount) + +parseFloat(freight);

		setBill({
			total: total,
			grand_total: grandTotal,
			freight: freight,
			tax_amount: taxAmount,
			discount_amount: discount_amount
		});
	}

	const onFreightChange = e => {
		console.log(e)
		let total = bill.total;
		let freight = parseFloat(e) || 0;
		let taxp = taxPercentage;
		let discount_p = discountPercentage;
		let discount_amount = (parseFloat(total) * discount_p / 100) || 0;
		let totalBeforeTax = (parseFloat(total)) - parseFloat(discount_amount);
		let taxAmount = (parseFloat(totalBeforeTax) * (parseFloat(taxp) / 100)) || 0;
		let grandTotal = totalBeforeTax + taxAmount + parseFloat(freight);

		setBill({
			total: total,
			grand_total: grandTotal,
			freight: freight,
			tax_amount: taxAmount,
			discount_amount: discount_amount
		});
	}

	const handleItemChange = (e, id, field_name) => {
		const list = [...state];
		if (field_name === "item_id") {
			// console.log("pr", getProduct(e))
			let prd = product.filter((item, index) => item.id === e)
			list[id]["item_id"] = prd[0]?.id
			list[id]["name"] = prd[0]?.name
			list[id]["code"] = prd[0]?.sku
			list[id]["current_stock"] = prd[0]?.onhand
			list[id]["price"] = prd[0]?.retail_price
		} else if (field_name === 'qty') {
			list[id][field_name] = e
			let field_total = parseFloat(e).toFixed(2) * parseFloat(list[id]["price"]).toFixed(2)
			list[id]["amount"] = +field_total.toFixed(2)
		} else if (field_name === 'price') {
			list[id][field_name] = e
			let field_total = parseFloat(e).toFixed(2) * parseFloat(list[id]["qty"]).toFixed(2)
			list[id]["amount"] = +field_total.toFixed(2)
		}
		let total = 0.00;
		list.map((item, ind) =>
			total += +item.amount
		)
		let grandTotal = bill.grand_total;
		let freight = bill.freight;
		let taxAmount = bill.tax_amount;
		let discount_amount = bill.discount_amount
		if (discountPercentage > 0) {
			let tax_p = taxPercentage;
			discount_amount = parseFloat(total) * discountPercentage / 100;
			let totalBeforeTax = parseFloat(total) - parseFloat(discount_amount);
			taxAmount = parseFloat(totalBeforeTax) * (parseFloat(tax_p) / 100);
			grandTotal = totalBeforeTax + taxAmount + +parseFloat(freight);
		}

		if (taxPercentage > 0) {
			let tax_p = taxPercentage;
			let discount_p = discountPercentage;
			discount_amount = parseFloat(total) * discount_p / 100;
			let totalBeforeTax = parseFloat(total) - parseFloat(discount_amount);
			taxAmount = parseFloat(totalBeforeTax) * (parseFloat(tax_p) / 100);
			grandTotal = parseFloat(totalBeforeTax) + parseFloat(taxAmount) + +parseFloat(freight);
		}
		setBill({
			total: total,
			grand_total: grandTotal,
			freight: freight,
			tax_amount: taxAmount,
			discount_amount: discount_amount
		});
		setState(list)
	}

	const handleRemoveRow = (idx) => e => {
		console.log(idx)
		let row = state.filter((item, index) => index === idx);
		if(row[0].id > 0){
			// call api
			axios.delete(Config.apiserver+'entries/salesitemdelete/'+row[0].id)
				.then(res => {
					if(res.data.status === 1){
						message.success(res.data.msg)
					}
					else{
						message.error(res.data.msg)
					}
				})
				.catch(error => console.log(error))
		}

		const newArray = state.filter((item, index) => index !== idx);
		setState(newArray);
	}

	if (loader) {
		return <CircularProgress/>;
	}

	return (
		<Card title={jsonfile.edittitle} extra={CisUI().addAction('../../' + jsonfile.urls.list)}>
			{CisUI().showLoading}

			<Form
				form={form}
				{...CisUI().formItemLayout}
				// name="input"
				// className="ant-advanced-search-form"
				onFinish={onFinish}
				initialValues={{remember: true}}
			>
				<div className="row">
					<div className="col-md-3 col-sm-6">
						<div className="gx-form-row0">
							<Form.Item
								name="beneficiary_source"
								label="Beneficiary/Source Type"
								rules={[
									{
										required: true,
										message: "Select a Beneficiary/source"
									},
								]}
							>
								<Select
									showSearch
									placeholder="Select an payment source"
									optionFilterProp="children"
									onChange={onBeneficiaryChange}
									filterOption={(input, option) => option.props.children?.toString().toLowerCase().indexOf(input?.toLowerCase()) >= 0 || false}
								>
									{
										BeneficiaryType.map((items, index) =>
											<Option key={++index} value={items.id}>{items.name}</Option>
										)
									}
								</Select>
							</Form.Item>
						</div>
					</div>
					<div className="col-md-3 col-sm-6">
						<div className="gx-form-row0">
							<Form.Item
								name="beneficiary_id"
								label="Beneficiary"
								rules={[
									{
										required: true,
										message: "Select a beneficiary"
									},
								]}
							>
								<Select
									showSearch
									placeholder="Select an beneficiary"
									optionFilterProp="children"
									filterOption={(input, option) => option.props.children?.toString().toLowerCase().indexOf(input?.toLowerCase()) >= 0 || false}
								>
									{
										selectedBeneficiary.map((items, index) =>
											<Option key={++index} value={items.id}>{items.name}</Option>
										)
									}
								</Select>
							</Form.Item>
						</div>
					</div>
					<div className="col-md-3 col-sm-6">
						<div className="gx-form-row0">
							<Form.Item
								name="account_id"
								label="Income Account"
								rules={[
									{
										required: true,
										message: "Select an account"
									},
								]}
							>
								<Select
									showSearch
									placeholder="Select an account"
									optionFilterProp="children"
									filterOption={(input, option) => option.props.children?.toString().toLowerCase().indexOf(input?.toLowerCase()) >= 0 || false}
								>
									{
										IncomeAccount.map((items, index) =>
											<Option key={++index} value={items.id}>{items.name}</Option>
										)
									}
								</Select>
							</Form.Item>
						</div>
					</div>
					<div className="col-md-3 col-sm-6">
						<div className="gx-form-row0">
							<Form.Item
								name="receiveable_id"
								label="Account Receiveable"
								rules={[
									{
										required: true,
										message: "Select an account"
									},
								]}
							>
								<Select
									showSearch
									placeholder="Select an account"
									optionFilterProp="children"
									filterOption={(input, option) => option.props.children?.toString().toLowerCase().indexOf(input?.toLowerCase()) >= 0 || false}
								>
									{
										AccountReceiveAble.map((items, index) =>
											<Option key={++index} value={items.id}>{items.name}</Option>
										)
									}
								</Select>
							</Form.Item>
						</div>
					</div>
					<div className="col-md-2 col-sm-6">
						<div className="gx-form-row0">
							<Form.Item
								name="shipping_method"
								label="Shipping Method"
								rules={[
									{
										required: false,
										message: "Select a shipping method"
									},
								]}
							>
								<Select
									showSearch
									placeholder="Select a shipping method"
									optionFilterProp="children"
									filterOption={(input, option) => option.props.children?.toString().toLowerCase().indexOf(input?.toLowerCase()) >= 0 || false}
								>
									{
										ShippingMethod.map((items, index) =>
											<Option key={++index} value={items.id}>{items.name}</Option>
										)
									}
								</Select>
							</Form.Item>
						</div>
					</div>
					<div className="col-md-2 col-sm-6">
						<div className="gx-form-row0">
							<Form.Item
								name="sales_type"
								label="Sale Type"
								initialValue="Regular"
								rules={[
									{
										required: false,
										message: "Select a sale type"
									},
								]}
							>
								<Select
									showSearch
									placeholder="Select a sale type"
									optionFilterProp="children"
									filterOption={(input, option) => option.props.children?.toString().toLowerCase().indexOf(input?.toLowerCase()) >= 0 || false}
								>
									{
										SaleType.map((items, index) =>
											<Option key={++index} value={items.id}>{items.name}</Option>
										)
									}
								</Select>
							</Form.Item>
						</div>
					</div>
					<div className="col-md-2 col-sm-6">
						<div className="gx-form-row0">
							<Form.Item
								name="discount_pp"
								label="Discount (%)"
								onChange={onDiscountChange}
								rules={[
									{
										required: false,
										message: "Payment Terms"
									},
								]}
							>
								<Input placeholder="0"/>
							</Form.Item>
						</div>
					</div>
					<div className="col-md-2 col-sm-6">
						<div className="gx-form-row0">
							<Form.Item
								name="sales_tax_pp"
								label="Sales Tax (%)"
								onChange={onTaxChange}
								rules={[
									{
										required: false,
										message: "Enter sales tax amount"
									}
								]}
							>
								<Input placeholder="0"/>
							</Form.Item>
						</div>
					</div>
					<div className="col-md-2 col-sm-6">
						<div className="gx-form-row0">
							<Form.Item
								name="order_date"
								label="Order Date"
								rules={[
									{
										required: true,
										message: "Order date"
									},
								]}
							>
								<DatePicker className="gx-w-100" format={CisUI().dateFormat} placeholder="Order Date"/>
							</Form.Item>
						</div>
					</div>
					<div className="col-md-2 col-sm-6">
						<div className="gx-form-row0">
							<Form.Item
								name="due_date"
								label="Due Date"
								rules={[
									{
										required: true,
										message: "Due date"
									},
								]}
							>
								<DatePicker className="gx-w-100" format={CisUI().dateFormat} placeholder="Due Date"/>
							</Form.Item>
						</div>
					</div>
				</div>

				<div className="row">
					<div className="col-md-12">
						<div className="row">
							<div className="col-md-6">
								<h2>Sales Items</h2>
							</div>
							<div className="col-md-6" style={{textAlign: "right"}}>
								<a className="btn btn-primary" onClick={handleAddRow}>Add More</a>
							</div>
						</div>
						<table id="itemtable" className="ptable table table-bordered">
							<thead>
                                <tr>
                                    <th></th>
                                    <th>Items</th>
                                    <th>Current Stock</th>
                                    <th>Quantity</th>
                                    <th>Unit Price(₵)</th>
                                    <th>Amount</th>
                                </tr>
							</thead>
							<tbody>
							{
								state.map((item, idx) =>
									<tr id="addr0" key={idx}>
										<td style={{width: '30px'}}>
											<a onClick={handleRemoveRow(idx)} className="btn btn-primary btn-circle"><i className="fa fa-trash"></i></a>
										</td>
										<td>
											<Select
												showSearch
												placeholder="Select an account"
												optionFilterProp="children"
												style={{width: '100%'}}
												onChange={(e) => handleItemChange(e, idx, 'item_id')}
												value={item.item_id}
												filterOption={(input, option) => option.props.children?.toString().toLowerCase().indexOf(input?.toLowerCase()) >= 0 || false}
											>
												{
													product.map((items, index) =>
														<Option key={++index}
														        value={items.id}>{items.sku}-{items.name} (Price: {items.retail_price})</Option>
													)
												}
											</Select>
										</td>
										<td style={{width: '100px', textAlign: 'right'}}>{item.current_stock}</td>
										<td style={{width: '100px'}}>
											<InputNumber className="gx-w-100 item"
											             onChange={(e) => handleItemChange(e, idx, 'qty')}
											             value={item.qty} placeholder="Quantity"/>
										</td>
										<td style={{width: '100px'}}>
											<InputNumber className="gx-w-100 item"
											             onChange={(e) => handleItemChange(e, idx, 'price')}
											             value={item.price} placeholder="Price"/>
										</td>
										<td style={{width: '100px'}}>
											<InputNumber className="gx-w-100 item" style={{color: "black"}}
											             value={item.amount} placeholder="Amount" disabled={true}/>
										</td>
									</tr>
								)
							}
							</tbody>
							<tfoot>
                                <tr>
                                    <td style={{textAlign: 'right', fontWeight: 'bold'}} colSpan={5}>Gross Total</td>
                                    <td style={{
                                        textAlign: 'right',
                                        fontWeight: 'bold'
                                    }}>{CisUI().getCurrencyFormated1(bill.total || 0)}</td>
                                </tr>
                                <tr>
                                    <td style={{textAlign: 'right'}} colSpan={5}>Discount</td>
                                    <td>
                                        <input type="text" className="form-control" id="discount" readOnly={true}
                                               style={{textAlign: 'right'}} value={bill.discount_amount || 0}
                                               name="discount"
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{textAlign: 'right', fontWeight: 'bold'}} colSpan={5}>After Discount</td>
                                    <td style={{textAlign: 'right', fontWeight: 'bold'}}>
                                        {CisUI().getCurrencyFormated1((parseFloat(bill.total || 0) - parseFloat(bill.discount_amount || 0)))}
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{textAlign: 'right'}} colSpan={5}>Sales Tax</td>
                                    <td>
                                        <input type="text" className="form-control" id="tax" readOnly={true}
                                               style={{textAlign: 'right'}}
                                               value={(bill.tax_amount || 0).toFixed(2)}
                                               name="tax"
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{textAlign: 'right'}} colSpan={5}>Freight</td>
                                    <td>
                                        <InputNumber className="gx-w-100 item" id="freight" value={bill.freight || 0}
                                                     onChange={(e) => onFreightChange(e)}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{textAlign: 'right', fontWeight: 'bold'}} colSpan={5}>Grand Total</td>
                                    <td style={{ textAlign: 'right', fontWeight: 'bold'}}>{CisUI().getCurrencyFormated1(bill.grand_total || 0)}</td>
                                </tr>
							</tfoot>
						</table>
					</div>
				</div>

				<div className="row">
					<div className="col-md-12" style={{textAlign: 'center', marginTop: '30px'}}>
						<Button id="submitbutton" type="primary" htmlType="submit">
							Save Sales Order
						</Button>
						<a className="ant-btn ant-alert-info" onClick={() => history.goBack()}>Cancel</a>
					</div>
				</div>

			</Form>


		</Card>
	);
};

export default EditCorporateSales;