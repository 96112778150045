import React, { useState, useEffect } from "react";
import {Row, Col, Input, Card, Table, Collapse, Form} from "antd";
import { useHistory } from "react-router-dom";
import { SearchOutlined } from "@ant-design/icons";
import { CisUI } from "../../../../util/CISUI";
import axios from "axios";
import config from "../../../../util/config";
import jsonfile from "./product.json";
import Config from "../../../../util/config";
import ActionButton from "../../../../util/actionbutton";
import CircularProgress from "../../../../components/CircularProgress";
import axiosinstance from "../../../../util/Api";
import AdvancedSearch from "../../../../util/AdvancedSearch";
const Panel = Collapse.Panel;
const columns = [
  {
    title: "SL",
    dataIndex: "sl",
    key: "sl",
  },
  {
    title: "Product Name",
    dataIndex: "name",
    key: "name"
  },
  {
    title: "Product SKU",
    dataIndex: "sku",
    key: "sku",
  },
  {
    title: "Category",
    dataIndex: "category",
    key: "category",
  },
  {
    title: "Sub Category",
    dataIndex: "subcategory",
    key: "subcategory",
  },
  {
    title: "Vendor",
    dataIndex: "vendor",
    key: "vendor",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
  },
  {
    title: "Actions",
    dataIndex: "action",
    key: "action",
  },
];

const ProductList = () => {
  const [loader, setLoader] = useState(false);
  const [products, setProducts] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [perPage, setPerPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [tableData, setTableData] = useState([]);
  const history = useHistory();
  const [state, setState] = useState({
    reqURL: Config.apiserver + jsonfile.urls.list,
    filtervalue: '',
    subtitle: '',
  });

  const getproduct = () => {
    setLoader(true);
    axiosinstance.get(state.reqURL)
        .then((res) => {
          console.log(res);
          if(res.data.status === 1){
            setTotalData(res?.data?.data?.total)
            setPerPage(res?.data?.data?.per_page)
            let data = [];
            res?.data?.data?.data?.map((product, i) => {
              data.push({
                key: product.id,
                sl: ++i,
                name: product.name,
                sku: product.sku,
                category: product.category_name,
                subcategory: product.subcategory_name,
                vendor: product.vendor_name,
                status: CisUI().getStatusLabel(product.status),
                action: (
                    ActionButton(product.id,jsonfile.urls,jsonfile.urls.list)
                ),
              });
            });
            setProducts(data);

          }
          setLoader(false);
        })
        .catch((errors) => console.log(errors));
  };

  useEffect(() => {
    getproduct();
  }, [state.reqURL]);

  const handlePagination = (page, pageSize) => {
    console.log("page", page)
    console.log("page", pageSize)
    let newURL = Config.apiserver+jsonfile.urls.list+"/?page="+page;
    setCurrentPage(page)
    setState({reqURL: newURL});
  }

  const onSearch = ((e) => {
    console.log(e.target.value)

    const salesproduct = [];

    products.map((product, i) => {
      if (product.name.toLowerCase().includes(e.target.value.toLowerCase())) {
        salesproduct.push({
          key: product.id,
          sl: ++i,
          name: product.name,
          sku: product.sku,
          category: product.category_name,
          subcategory: product.subcategory_name,
          vendor: product.vendor_name,
          status: CisUI().getStatusLabel(product.status),
          action: (
              ActionButton(product.id,jsonfile.urls,jsonfile.urls.list)
          ),
        });
      }
    })
    setTableData(salesproduct)
  });



    // const data = [];
    //
    // products.map((product, i) => {
    //   data.push({
    //     key: product.id,
    //     sl: ++i,
    //     name: product.name,
    //     sku: product.sku,
    //     category: product.category_name,
    //     subcategory: product.subcategory_name,
    //     vendor: product.vendor_name,
    //     status: CisUI().getStatusLabel(product.status),
    //     action: (
    //         ActionButton(product.id,jsonfile.urls,jsonfile.urls.list)
    //     ),
    //   });
    // });

  const [form] = Form.useForm();

  const onFinish = values => {
    // console.log('Received values of form: ', values);
    const data = {
      ...values,
      // 'purchase_date' : values['purchase_date'].format("YYYY-MM-DD"),
    }
    // console.log(data);
    const qs = Object.keys(data).map(key => `${key}=${data[key]}`).join('&');
    const qsup = qs.replaceAll("undefined","");
    //reqURL = reqURL+"/?"+qsup;
    history.push("?"+qsup);
    const newURL = Config.apiserver+jsonfile.urls.list+"/?"+qsup;
    setState({reqURL: newURL});
  };

  let  searchView = "";
  if(jsonfile.has_search) {
    searchView = <Card className="advance-search-card">
      <Collapse
          accordion
          expandIconPosition="right"
          defaultActiveKey={['1']} >
        <Panel header="Advance Search"  key="1">
          <Form
              key={5}
              form={form}
              {...CisUI().formItemLayout}
              name="advanced_search"
              className="ant-advanced-search-form"
              onFinish={onFinish}
          >
            {AdvancedSearch(jsonfile.input,"",0)}
          </Form>
        </Panel>
      </Collapse>
    </Card>
  }

  if(loader){
    return <CircularProgress />;
  }

  return (
    <>
      {searchView}
      <Card title={"Products List"} extra={CisUI().ListActionButtonV2("../" + jsonfile.urls.add, "", state.reqURL, jsonfile)}>

        <Row className="list-header">
          <Col xs={{ span: 24 }} xl={{ span: 18 }}></Col>

          {/*<Col xs={{ span: 24 }} xl={{ span: 6 }}>*/}
          {/*  <Input className="list-header-search" placeholder="Search Product" prefix={<SearchOutlined />} onChange={onSearch} />*/}
          {/*</Col>*/}
        </Row>
        <Table
            scroll={{ x: true }}
            columns={columns}
            dataSource={products}
            className="list-table"
            bordered
            pagination={{
              total: totalData,
              defaultPageSize: perPage,
              current: currentPage,
              onChange: (page, pageSize) => handlePagination(page, pageSize),
            }}
        />
      </Card>

    </>
  );
};

export default ProductList;


