import React, {useState, useEffect} from "react";
import { Form, Card, Collapse} from 'antd';
import jsonfile from './leads.json';
import {Link, useHistory} from "react-router-dom";
import {CisUI} from '../../../../util/CISUI';
import AdvancedSearch from '../../../../util/AdvancedSearch';
import ActionButton from '../../../../util/actionbutton';
import SmartDataTable from 'react-smart-data-table';
import 'react-smart-data-table/dist/react-smart-data-table.css';
import IntlMessages from "util/IntlMessages";
import Config from "../../../../util/config";
import axiosinstance from "../../../../util/Api";
import CircularProgress from "../../../../components/CircularProgress";

const Panel = Collapse.Panel;

const LeadList = () => {
    const [loader, setLoader] = useState(false);
    const [apiData, setAPIData] = useState([]);
    const history = useHistory();
    const [state,setState] = useState({
        reqURL: Config.apiserver+jsonfile.urls.list,
        filtervalue : '',
        subtitle: ""
    });

    useEffect(() => {
        setLoader(true)
        axiosinstance.get(state.reqURL)
            .then(res => {
                if(res.data.status === 1){
                    setAPIData(res.data.data)
                    CisUI().createNotification('error', res.data.msg)
                }
                else{
                    CisUI().createNotification('error', res.data.msg)
                }
                setLoader(false)
            })
            .catch(error => {
                console.log(error)
                setLoader(false)
            })
    }, [state.reqURL]);

    const headers = {
        id: {
            text: <IntlMessages id="Sl NO"/>,
            sortable: true,
            filterable: true,
        },
        company_name: {
            text: <IntlMessages id="Company Name"/>,
            sortable: true,
            filterable: true,
        },
        customer_type: {
            text: <IntlMessages id="Customer Category"/>,
            sortable: true,
            filterable: true,
        },
        full_name: {
            text: <IntlMessages id="Name"/>,
            sortable: true,
            filterable: true,
        },
        email: {
            text: <IntlMessages id="Email"/>,
            sortable: true,
            filterable: true,
        },
        phone: {
            text: <IntlMessages id="Phone"/>,
            sortable: true,
            filterable: true,
        },
        status: {
            text: <IntlMessages id="Status"/>,
            sortable: true,
            filterable: true,
            transform: (value, idx, row) => (
                CisUI().getStatusLabel(value)
            )
        },
        actions: {
            text: <IntlMessages id="Actions"/>,
            sortable: false,
            filterable: false,
            transform: (value, idx, row) => (
                <>
                    {ActionButton(row.id,jsonfile.urls,jsonfile.urls.list)}
                </>
            ),
        },
    };

    const orderedHeaders = jsonfile.listView.title;

    const [form] = Form.useForm();

    const onFinish = values => {
        // console.log('Received values of form: ', values);
        const qs = Object.keys(values).map(key => `${key}=${values[key]}`).join('&');
        const qsup = qs.replaceAll("undefined","");
        //reqURL = reqURL+"/?"+qsup;
        history.push("?"+qsup);
        const newURL = Config.apiserver+jsonfile.urls.list+"/?"+qsup;
        setState({reqURL: newURL});
    };

    let  searchView = "";
    if(jsonfile.has_search) {
        searchView = <Card className="advance-search-card">
            <Collapse
                accordion
                expandIconPosition="right"
                defaultActiveKey={['1']} >
                <Panel header={<IntlMessages id="Advance Search" />}  key="1">
                    <Form
                        key={5}
                        form={form}
                        {...CisUI().formItemLayout}
                        name="advanced_search"
                        className="ant-advanced-search-form"
                        onFinish={onFinish}
                    >
                        {AdvancedSearch(jsonfile.input)}
                    </Form>
                </Panel>
            </Collapse>
        </Card>
    }

    const customeBtn = (<Link to={"../crm/newinvoice"} className="btn btn-text-adjust" title="Add New">
        <i className="fas fa-plus"/> New Invoice
    </Link>);

    if(loader){
        return <CircularProgress />;
    }

    return (
        <>
            {searchView}
            <Card title={<IntlMessages id={jsonfile.listtitle}/>}
                  extra={CisUI().ListActionButtonV2("../"+jsonfile.urls.add, "", state.reqURL,jsonfile, customeBtn)}
            >
                <SmartDataTable
                    name='test-fake-table'
                    data={apiData}
                    dataRequestOptions={CisUI().HeaderRequest}
                    dataKey="data"
                    headers={headers}
                    orderedHeaders={orderedHeaders}
                    hideUnordered={CisUI().hideUnordered}
                    className={CisUI().sematicUI.table}
                    filterValue={state.filterValue}
                    perPage={CisUI().perPage}
                    sortable
                    withLinks
                    withHeader
                    loader={CisUI().loader}
                    parseBool={{
                        yesWord: 'Yes',
                        noWord: 'No',
                    }}
                    parseImg={{
                        style: {
                            border: '1px solid #ddd',
                            borderRadius: '50px',
                            padding: '3px',
                            width: '30px',
                            height: '30px'
                        },
                    }}
                    emptyTable={CisUI().emptyTable}
                />
            </Card>
        </>
    );
};

export default LeadList;