import React from "react";
import {Route, Switch} from "react-router-dom";

import NewEmployee from "../../containers/yotta/ems_hrm/EmployeeInformation/NewEmployee";
import EvaluationForm from "../../containers/yotta/ems_hrm/EvaluationForm/EvaluationForm";
import EmployeeExit from "../../containers/yotta/ems_hrm/EmployeeExitForm/EmployeeExit";
import DepositAuthorization from "../../containers/yotta/ems_hrm/DepositAuthorization/DepositAuthorization";
// import ChangeInAddress from "../../containers/yotta/ems_hrm/ChangeInAddress/ChangeInAddress";
// import PhoneForm from "../../containers/yotta/ems_hrm/EmployeePhoneForm/PhoneForm";


const HRM = ({match}) => (
    <Switch>
        <Route path={`${match.url}/ems/newemployee`} component={NewEmployee}/>
        <Route path={`${match.url}/ems/newevalution`} component={EvaluationForm}/>
        <Route path={`${match.url}/ems/newempexit`} component={EmployeeExit}/>
        <Route path={`${match.url}/ems/newdepositauthorization`} component={DepositAuthorization}/>
        {/*<Route path={`${match.url}/ems/newchangeaddress`} component={ChangeInAddress}/>*/}
        {/*<Route path={`${match.url}/ems/newphoneform`} component={PhoneForm}/>*/}
    </Switch>
);

export default HRM;
