import React from "react";
import {Route, Switch} from "react-router-dom";
import Donation from "../donation";
import SingleVoucher from "../../containers/yotta/entries/singlevoucher";
import SalesOrderHistory from "../../containers/yotta/sales/order/SalesOrderHistory";
import PurchaseOrderList from "../../containers/yotta/purchase/list";
import AllVoucherList from "../../containers/yotta/entries/allvoucherlist";
import Quotation from "../../containers/yotta/sales/order/quotation";
import NewOrderImageView from "../../containers/yotta/sales/order/NewOrderListView";
import NewServiceInvoice from "../../containers/yotta/crm/invoice/v2/NewInvoice";
import CorporateSales from "../../containers/yotta/sales/order/CorporateSales";
import SalesOrderDetails from "../../containers/yotta/sales/order/Salesorderdetails";
import InvoiceList from "../../containers/yotta/crm/invoice/v2/InvoiceList";
import InvoiceDetails from "../../containers/yotta/crm/invoice/v2/InvoiceDetails";
import Overdueinvoice from "../../containers/yotta/sales/order/overdueinvoice";
import EditCorporateSales from "../../containers/yotta/sales/order/EditCorporateSales";

const Sales = ({match}) => (
    <Switch>
        <Route path={`${match.url}/salesorder`} component={SalesOrderHistory}/>
        <Route path={`${match.url}/purchseregister`} component={PurchaseOrderList}/>
        <Route path={`${match.url}/creditnote`} component={AllVoucherList}/>
        <Route path={`${match.url}/debitnote`} component={AllVoucherList}/>
        <Route path={`${match.url}/receiptsregister`} component={AllVoucherList}/>
        <Route path={`${match.url}/paymentregister`} component={AllVoucherList}/>
        <Route path={`${match.url}/quotation`} component={Quotation}/>
        <Route path={`${match.url}/coporatesales`} component={NewOrderImageView}/>
        <Route path={`${match.url}/serviceinvoice`} component={NewServiceInvoice}/>
        <Route path={`${match.url}/newsalesorder`} component={CorporateSales}/>
        <Route path={`${match.url}/newsalesinvoice`} component={Quotation}/>
        <Route path={`${match.url}/srvinvoicelist`} component={InvoiceList}/>
        <Route path={`${match.url}/saleshistory`} component={SalesOrderHistory}/>
        <Route path={`${match.url}/overdueinvoice`} component={Overdueinvoice}/>
        <Route path={`${match.url}/salesdetails/:id`} component={SalesOrderDetails}/>
        <Route path={`${match.url}/salesedit/:id`} component={EditCorporateSales}/>
        <Route path={`${match.url}/invoicedetails/:id`} component={InvoiceDetails}/>
    </Switch>
)

export default Sales;