import React from "react";
import {Scrollbars} from "react-custom-scrollbars";

const CustomScrollbars = (props) => {
	const thumbStyle = {
		backgroundColor: "#426a8e",
		borderRadius: "5px",
	};
	return (
		<Scrollbars
			{...props}
			autoHide={false}
			renderThumbVertical={({style, ...props}) => (
				<div {...props} style={{...style, ...thumbStyle}}/>
			)}
			renderTrackHorizontal={
				props =>
					<div {...props}
					     style={{display: 'none'}}
					     className="track-horizontal"
					/>
			}
		/>
	);
}

export default CustomScrollbars;
