import React, {useState} from "react";
import {Row, Col, Form, Card, Input, Button, notification, Divider, Checkbox, Select, Upload, DatePicker} from 'antd';
import jsonfile from './evaluation_form.json';
import {useHistory} from "react-router-dom";
import RemoteJson from "../../../../util/gerremotedata";
import InputFieldV2 from "../../../../util/InputFieldV2";
import {CisUI} from "../../../../util/CISUI";
import Config from "../../../../util/config";
import axios from "axios";
import {UploadOutlined} from "@ant-design/icons";

const EvaluationForm = (props) => {
	const [probationarySummary, setProbationarySummary] = useState(1);

	const history = useHistory();
	const {form} = Form.useForm();

	const [isShow, setIsShow] = useState(false);
	const handleResidence = (e) => {
		e.target.checked === true ? setIsShow(true) : setIsShow(false);
	}

	const userID = props.editEmpId;
	let userData = [];
	// console.log("Userid : "+props.match.params.id);
	let isEdit = 0;
	if(userID > 0) {
		isEdit = 1;
	}
	let Title = jsonfile.addtitle;
	let endpoint = jsonfile.urls.add;
	let redirectto = "../"+jsonfile.urls.list;

	if(isEdit === 1 ) {
		userData = RemoteJson(jsonfile.urls.view+"/"+userID);
		Title = jsonfile.edittitle;
		endpoint = jsonfile.urls.edit+"/"+userID;
		redirectto = "../../"+jsonfile.urls.list;
		// props.empid(userID);
	}
	console.log("General", userData)

	const onFinish = (values) => {
		// console.log("Input Data : " + values);
		document.getElementById("loader").style.display = "block";
		axios.post(Config.apiserver + endpoint, values, CisUI().HeaderRequest)
			.then(res => {
				// console.log(res)
				if(res.data.status === 1) {
					notification.success({
						message: 'Success',
						type : "success",
						description: res.data.msg
					});
					props.empid(res.data.emp_id)
					// history.push(redirectto);
				}
				else {
					notification.warning({
						message: 'Alert',
						type : "warning",
						description: res.data.msg
					});
				}
				document.getElementById("loader").style.display = "none";
			})
			.catch(errors => {
				console.log(errors);
			});
	}

	const handlePVSummary = (value) => {
		console.log("Prob",value)
		setProbationarySummary(value)
	}

	return (
		<Card title={isEdit === 1 ? jsonfile.edittitle : jsonfile.addtitle}
		      extra={
			      <>
				      {
					      isEdit === 1
						      ? CisUI().addAction(redirectto)
						      : CisUI().addAction(redirectto)
				      }
			      </>
		      }
		>
			{CisUI().showLoading}
			<Form
				form={form}
				{...CisUI().formItemLayout}
				name="input"
				className="ant-advanced-search-form"
				onFinish={onFinish}
				initialValues={{ remember: true }}
			>
				{InputFieldV2(jsonfile.input,userData,isEdit,props)}
				<Row gutter={24}>
					<Col lg={24} md={24} sm={24} xs={24}>
						<p style={{ margin: "10px 0", color: "Blue"}}><span style={{ fontWeight: "bold"}}>**Note:</span> LINE MANAGER TO COMPLETE THIS FORM AFTER A REVIEW WITH THE EMPLOYEE ON HIS/HER PERFORMANCE</p>
					</Col>

					<Col lg={8} md={12} sm={24} xs={24}>
						<div className="gx-form-row0">
							<Form.Item
								name="objective1"
								label="Objective 1"
							>
								<Input.TextArea placeholder="Explain (Please provide supporting evidence)"/>
							</Form.Item>
						</div>
					</Col>
					<Col lg={8} md={12} sm={24} xs={24}>
						<div className="gx-form-row0">
							<Form.Item
								name="objective2"
								label="Objective 2"
							>
								<Input.TextArea placeholder="Explain (Please provide supporting evidence)"/>
							</Form.Item>
						</div>
					</Col>
					<Col lg={8} md={12} sm={24} xs={24}>
						<div className="gx-form-row0">
							<Form.Item
								name="objective3"
								label="Objective 3"
							>
								<Input.TextArea placeholder="Explain (Please provide supporting evidence)"/>
							</Form.Item>
						</div>
					</Col>

					<Divider orientation="left" plain><span style={{ fontSize: "16px", fontWeight: "bold"}}>Performance Summary</span></Divider>
					<Col lg={24} md={24} sm={24} xs={24}>
						<table className="table">
							<thead>
								<tr>
									<th>SUMMARY OF PERFORMANCE</th>
									<th>STATUS</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>ATTITUDE TO WORK</td>
									<td>
										<Form.Item
											name="work_status"
										>
											<Select
												placeholder="Please Select An Option"
											>
												<Select.Option value={1}>SATISFACTORY</Select.Option>
												<Select.Option value={2}>TERMINATED</Select.Option>
											</Select>
										</Form.Item>
									</td>
								</tr>
								<tr>
									<td>WORKING WITH OTHERS</td>
									<td>
										<Form.Item
											name="working_status"
										>
											<Select
												placeholder="Please Select An Option"
											>
												<Select.Option value={1}>SATISFACTORY</Select.Option>
												<Select.Option value={2}>TERMINATED</Select.Option>
											</Select>
										</Form.Item>
									</td>
								</tr>
								<tr>
									<td>ATTENDANCE TO WORK</td>
									<td>
										<Form.Item
											name="attendance_status"
										>
											<Select
												placeholder="Please Select An Option"
											>
												<Select.Option value={1}>SATISFACTORY</Select.Option>
												<Select.Option value={2}>TERMINATED</Select.Option>
											</Select>
										</Form.Item>
									</td>
								</tr>
								<tr>
									<td>WORK INITIATIVES</td>
									<td>
										<Form.Item
											name="initiative_status"
										>
											<Select
												placeholder="Please Select An Option"
											>
												<Select.Option value={1}>SATISFACTORY</Select.Option>
												<Select.Option value={2}>TERMINATED</Select.Option>
											</Select>
										</Form.Item>
									</td>
								</tr>
								<tr>
									<td>KNOWLEDGE OF THE ROLE</td>
									<td>
										<Form.Item
											name="role_status"
										>
											<Select
												placeholder="Please Select An Option"
											>
												<Select.Option value={1}>SATISFACTORY</Select.Option>
												<Select.Option value={2}>TERMINATED</Select.Option>
											</Select>
										</Form.Item>
									</td>
								</tr>
							</tbody>
						</table>
					</Col>

					<Divider orientation="left" plain><span style={{ fontSize: "16px", fontWeight: "bold"}}>OVERALL PROBATIONARY SUMMARY</span></Divider>
					<Col lg={8} md={12} sm={24} xs={24}>
						<div className="gx-form-row0">
							<Form.Item
								name="probationary_summary"
								label="Probationary Summary"
							>
								<Select
									onChange={handlePVSummary}
									placeholder="Please Select An Option"
								>
									<Select.Option value={1}>Confirmed</Select.Option>
									<Select.Option value={2}>Extended</Select.Option>
									<Select.Option value={3}>Terminated</Select.Option>
								</Select>
							</Form.Item>
						</div>
					</Col>
					{
						probationarySummary === 2 &&
						<Col lg={8} md={12} sm={24} xs={24}>
							<div className="gx-form-row0">
								<Form.Item
									name="extended_period"
									label="Extended Period"
								>
									<Input placeholder="Enter Extended Period"/>
								</Form.Item>
							</div>
						</Col>
					}
				</Row>
				<Row>
					<Col lg={12} md={12} sm={24} xs={24}>
						<div className="gx-form-row0">
							<Form.Item
								className="form-input"
								name="manager_signature"
								label="Manager Signature"
							>
								<Upload>
									<Button>
										<UploadOutlined/> Click to upload
									</Button>
								</Upload>
							</Form.Item>
						</div>
					</Col>
					<Col lg={12} md={12} sm={24} xs={24}>
						<div className="gx-form-row0">
							<Form.Item
								className="form-input"
								name="head_signature"
								label="HEAD OF DEPARTMENT SIGNATURE"
							>
								<Upload>
									<Button>
										<UploadOutlined/> Click to upload
									</Button>
								</Upload>
							</Form.Item>
						</div>
					</Col>
				</Row>
				<Row>
					<Divider orientation="left" plain><span style={{ fontSize: "16px", fontWeight: "bold"}}>FOR HR USE ONLY</span></Divider>
					<Col lg={24} md={24} sm={24} xs={24}>
						<div className="gx-form-row0">
							<Form.Item
								name="comment"
								label="Comment"
							>
								<Input.TextArea placeholder="Comment"/>
							</Form.Item>
						</div>
					</Col>
					<Col lg={8} md={12} sm={24} xs={24}>
						<div className="gx-form-row0">
							<Form.Item
								className="form-input"
								name="signature"
								label="Signature"
							>
								<Upload>
									<Button>
										<UploadOutlined/> Click to upload
									</Button>
								</Upload>
							</Form.Item>
						</div>
					</Col>
					<Col lg={8} md={12} sm={24} xs={24}>
						<div className="gx-form-row0">
							<Form.Item
								className="form-input"
								name="signature_date"
								label="Signature Date"
							>
								<DatePicker className="gx-w-100" format={CisUI().dateFormat}/>
							</Form.Item>
						</div>
					</Col>
				</Row>

				<Row gutter={24}>
					<Col lg={24} md={24} sm={12} xs={24} style={{textAlign: 'center'}}>
						<Button type="primary" htmlType="submit">
							Submit
						</Button>
						<Button type="info" onClick={() => history.goBack()} htmlType="submit">Back</Button>
					</Col>
				</Row>

			</Form>
		</Card>
	);
}

export default EvaluationForm;