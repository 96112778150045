module.exports = {
  footerText: '©️ 2024 The Church of Pentecost – General Headquarters. All Rights Reserved',
  version: 'v10.0.0',
  versionLogin: 'v1.0.0',
  currencySymbol : "¢",
  // loginTitle : "",
  loginTitle : "Training Version",
  
  /*
  * *****************************************
  *     Local Server                        *
  *     Use only local                      *
  * *****************************************
  */
  // attachement: 'http://127.0.0.1:8000/',
  // apiserver: 'http://127.0.0.1:8000/api/',
  // baseurl: 'http://localhost:3000/',
  // fileserver: 'http://127.0.0.1:8000/',
  // profile_pic_url: 'http://127.0.0.1:8000/public/upload/profile/',
  
  // attachement: 'http://192.168.68.149:8000/',
  // apiserver: 'http://192.168.68.149:8000/api/',
  // baseurl: 'http://localhost:3000/',
  // fileserver: 'http://192.168.68.149:8000/',
  // profile_pic_url: 'http://192.168.68.149:8000/public/upload/profile/',
  
  /*
  * *****************************************
  *     Staging Server                      *
  *     Use only staging                    *
  * *****************************************
  */
  attachement: 'https://pocapi.datalibrary.io',
  apiserver: 'https://pocapi.datalibrary.io/api/',
  baseurl: 'https://pocstaging.datalibrary.io/clear',
  fileserver: 'https://pocapi.datalibrary.io/',
  profile_pic_url: 'https://pocapi.datalibrary.io/public/upload/profile/',
  

  // attachement: 'https://apiv2.penterp.com/public',
  // apiserver: 'https://apiv2.penterp.com/api/',

  // attachement: 'https://apiv3.penterp.com/public',
  // apiserver: 'https://apiv2.penterp.com/api/',
  // apiserver: 'https://apiv3.penterp.com/api/',

  // baseurl: 'https://office.penterp.com/',
  // baseurl: 'http://localhost:3000/',
  // baseurl: 'https://v3.penterp.com/',
  // fileserver: 'https://apiv2.penterp.com/public',
  // fileserver: 'https://apiv3.penterp.com/public',
  // profile_pic_url: 'https://apiv2.penterp.com/public/upload/profile/',
  // profile_pic_url: 'https://apiv3.penterp.com/public/upload/profile/',

  //production
  // attachement: 'https://finapi.penterp.com/public',
  // apiserver: 'https://finapi.penterp.com/api/',
  // baseurl: 'https://fin.penterp.com/',
  // fileserver: 'https://finapi.penterp.com/public',
  // profile_pic_url: 'https://finapi.penterp.com/public/upload/profile/',

  //test
  //production
  // attachement: 'https://trainingapi.penterp.com/public',
  // apiserver: 'https://trainingapi.penterp.com/api/',
  // baseurl: 'https://training.penterp.com/',
  // fileserver: 'https://trainingapi.penterp.com/public',
  // profile_pic_url: 'https://trainingapi.penterp.com/public/upload/profile/',

  logourl : '/assets/images/penterpv1.png',
  // print_logourl : '/assets/images/penterpv1.png',
  print_logourl : '/assets/images/poc_logo.png',
  company_name : 'The Church of Pentecost HQ',
  address : 'The Church of Pentecost HQ P.O Box:  2034, Accra, Ghana',
  city : 'Wayne',
  state : 'New Jersey',
  zipcode : '11111',
  phone : "",
  email : "info@penterp.com",
  website : "https://penterp.com",
}


