import React, {useState} from "react";
import { Form, Col, Input,Card, Collapse} from 'antd';
import jsonfile from './sales.json';
import {Link, useHistory} from "react-router-dom";
import {CisUI} from '../../../../util/CISUI';
import AdvancedSearch from '../../../../util/AdvancedSearch';
import ActionButton from '../../../../util/actionbutton';
import SmartDataTable from 'react-smart-data-table';
import 'react-smart-data-table/dist/react-smart-data-table.css';
import IntlMessages from "util/IntlMessages";
import Config from "../../../../util/config";

const Panel = Collapse.Panel;

const SalesOrderHistory = () => {
    const history = useHistory();
    const [state,setState] = useState({
        reqURL: Config.apiserver+'entries/salesorder',
        filtervalue : '',
        subtitle:''
    });

    const [stdata,setStdata] = useState([]);
    const [selected,setSelected] = useState([]);


    const selectedForPrint = (e) => {
        let allselected = selected;
        if(e.target.checked === true) {
            if(!allselected.includes(e.target.value)) {
                allselected.push(e.target.value);
            }
        }
        else {
            var index = allselected.indexOf(e.target.value)
            if (index !== -1) {
                allselected.splice(index, 1);
            }
        }
        setSelected(allselected);

        if(allselected.length > 0) {
            document.getElementById("prntbtn").style.display = "block";
            setStdata(Object.values(allselected).join(','));
        }
        else {
            document.getElementById("prntbtn").style.display = "none";
            setStdata("");
        }
    }

    const clickTOPrint = (id) => {
        const url = Config.apiserver+"sales/salesinvoicelist?selected="+stdata;
        const ck = "../../multiinvoice.html?type=checkprint&url="+window.btoa(url)+"&selected="+stdata;
        window.open(ck, "_blank");
        return ck;
    }

    const checkAll = (e) => {
        var dcd = document.getElementById("datatable");
        var checkboxes = dcd.getElementsByTagName('input');
        let allselected = [];
        if(e.target.checked) {
            for(var i=1; i<checkboxes.length; i++) {
                console.log(i);
                if(checkboxes[i].checked) {
                    checkboxes[i].checked = false;
                }
                else {
                    checkboxes[i].checked = true;
                    if(!allselected.includes(checkboxes[i].value)) {
                        allselected.push(checkboxes[i].value);
                    }
                }
            }
        }else {
            allselected = [];
            for(var i=1; i<checkboxes.length; i++) {
                if(checkboxes[i].checked) {
                    checkboxes[i].checked = false;
                }
            }
        }
        setSelected(allselected);
        if(allselected.length > 0) {
            document.getElementById("prntbtn").style.display = "block";
            setStdata(Object.values(allselected).join(','));
        }
        else {
            document.getElementById("prntbtn").style.display = "none";
            setStdata("");
        }
    }

    const headers = {
        id: {
            text: <input type="checkbox" onChange={checkAll} name="checkAll" id="checkAll" value={1} />,
            sortable: true,
            filterable: false,
            transform: (value, idx, row) => (
                <input type="checkbox" onChange={selectedForPrint} name={"ck_"+value} value={value} />
            )
        },
        company_id: {invisible: true},
        date: {invisible: true},
        paid: {invisible: true},
        customer_id: {invisible: true},
        beneficiary_id: {invisible: true},
        beneficiary_source: {invisible: true},
        customer_name: {invisible: true},
        shipping_method: {invisible: true},
        sales_type: {invisible: true},
        vendor_id: {invisible: true},
        beneficiary_name: {
            text: <IntlMessages id="Beneficiary"/>,
            sortable: true,
            filterable: false
        },
        invoice_number: {
            text: <IntlMessages id="Invoice Number"/>,
            sortable: true,
            filterable: false,
            transform: (value, idx, row) => (
                <Link to={"salesdetails/"+row.id}>{value}</Link>
            )
        },
        required_by_date: {
            text: <IntlMessages id="Required By"/>,
            sortable: true,
            filterable: false,
            transform: (value, idx, row) => (
                CisUI().DateFormat(value)
            )
        },
        due_date: {
            text: <IntlMessages id="Due Date"/>,
            sortable: true,
            filterable: false,
            transform: (value, idx, row) => (
                CisUI().DateFormat(value)
            )
        },
        created_at: {
            text: <IntlMessages id="Created At"/>,
            sortable: true,
            filterable: false,
            transform: (value, idx, row) => (
                CisUI().DateFormat(value)
            )
        },
        total: {
            text: <IntlMessages id="Gross Total"/>,
            sortable: true,
            filterable: false,
            transform: (value, idx, row) => (
                CisUI().getCurrency(value)
            )
        },
        sales_tax: {
            text: <IntlMessages id="Sales Tax"/>,
            sortable: true,
            filterable: false,
            transform: (value, idx, row) => (
                CisUI().getCurrency(value)
            )
        },
        freight: {
            text: <IntlMessages id="Freight"/>,
            sortable: true,
            filterable: false,
            transform: (value, idx, row) => (
                CisUI().getCurrency(value)
            )
        },
        discount: {
            text: <IntlMessages id="Discount"/>,
            sortable: true,
            filterable: false,
            transform: (value, idx, row) => (
                CisUI().getCurrency(value)
            )
        },
        grand_total: {
            text: <IntlMessages id="Grand Total"/>,
            sortable: true,
            filterable: false,
            transform: (value, idx, row) => (
                CisUI().getCurrency(value)
            )
        },
        paid_amount: {
            text: <IntlMessages id="Paid"/>,
            sortable: true,
            filterable: false,
            transform: (value, idx, row) => (
                CisUI().getCurrency(value)
            )
        },
        due: {
            text: <IntlMessages id="Due"/>,
            sortable: true,
            filterable: false,
            transform: (value, idx, row) => (
                CisUI().getCurrency(value)
            )
        },
        status: {
            text: <IntlMessages id="Status"/>,
            sortable: true,
            filterable: true,
            transform: (value, idx, row) => (
                CisUI().getOrderStatus(value)
            )
        },
        actions: {
            text: 'Actions',
            sortable: false,
            filterable: false,
            transform: (value, idx, row) => (
                <>
                    {ActionButton(row.id,jsonfile.urls,jsonfile.urls.list)}
                </>
            ),
        },
    };

    const orderedHeaders = jsonfile.listView.title;

    const [expand, setExpand] = useState(false);
    const [form] = Form.useForm();

    const onFinish = values => {
        // console.log('Received values of form: ', values);
        const data = {
            ...values,
            // 'purchase_date' : values['purchase_date'].format("YYYY-MM-DD"),
        }
        // console.log(data);
        const qs = Object.keys(data).map(key => `${key}=${data[key]}`).join('&');
        const qsup = qs.replaceAll("undefined","");
        //reqURL = reqURL+"/?"+qsup;
        history.push("?"+qsup);
        const newURL = Config.apiserver+jsonfile.urls.list+"/?"+qsup;
        setState({reqURL: newURL});
    };

    // const filterValue = "";

    let  searchView = "";
    if(jsonfile.has_search) {
        searchView = <Card className="advance-search-card">
            <Collapse
                accordion
                expandIconPosition="right"
                defaultActiveKey={['1']} >
                <Panel header="Advance Search"  key="1">
                    <Form
                        key={5}
                        form={form}
                        {...CisUI().formItemLayout}
                        name="advanced_search"
                        className="ant-advanced-search-form"
                        onFinish={onFinish}
                    >
                        {AdvancedSearch(jsonfile.input,"",1)}
                    </Form>
                </Panel>
            </Collapse>
        </Card>
    }

    const getExtra = (
        <>
            {/*<Search*/}
            {/*    placeholder='Filter...'*/}
            {/*    name='filtervalue'*/}
            {/*    onSearch={handleOnFilter}*/}
            {/*    onChange={handleOnFilter}*/}
            {/*    style={{width: 200}}*/}
            {/*/>*/}
            {CisUI().listAction("../"+jsonfile.urls.add, "", state.reqURL, 'data', 'sales_order', jsonfile, state.subtitle, "landscape")}
        </>
    );

    return (
        <>
            {searchView}
            {CisUI().showLoading}
            <div className="row" id="prntbtn" style={{padding: '10px',  display: 'none'}}>
                <a onClick={clickTOPrint} className="btn btn-primary" style={{width: '100px'}} >Print</a>
            </div>
            <div id="datatable">
                <Card title={jsonfile.listtitle} extra={getExtra}>
                    {/*{CisUI().listAction(jsonfile.urls.add)}*/}
                    <SmartDataTable
                        name='test-fake-table'
                        data={state.reqURL}
                        dataRequestOptions={CisUI().HeaderRequest}
                        dataKey="data"
                        headers={headers}
                        orderedHeaders={orderedHeaders}
                        hideUnordered={CisUI().hideUnordered}
                        className={CisUI().sematicUI.table}
                        filterValue={state.filterValue}
                        perPage={CisUI().perPage}
                        sortable
                        withLinks
                        withHeader
                        // withToggles
                        loader={CisUI().loader}
                        parseBool={{
                            yesWord: 'Yes',
                            noWord: 'No',
                        }}
                        parseImg={{
                            style: {
                                border: '1px solid #ddd',
                                borderRadius: '50px',
                                padding: '3px',
                                width: '30px',
                                height: '30px'
                            },
                        }}
                        emptyTable={CisUI().emptyTable}
                    />
                </Card>
            </div>
        </>
    );
};

export default SalesOrderHistory;