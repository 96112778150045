import React, {useState, useEffect} from "react";
import {Form, Row, Col, Input, Button, Collapse, Popconfirm, Card, Table, Popover, notification} from 'antd';
import jsonfile from './voucher.json'
import {Link, useHistory, useRouteMatch} from "react-router-dom";
import {CisUI} from '../../../util/CISUI';
import AdvancedSearch from '../../../util/AdvancedSearch';
import ActionButton from '../../../util/actionbutton';
import ActionButton2 from '../../../util/ActionButton2';
import SmartDataTable from 'react-smart-data-table';
import 'react-smart-data-table/dist/react-smart-data-table.css';
import IntlMessages from "util/IntlMessages";
import Config from "../../../util/config";
import {isSet} from "lodash/lang";
import AdvancedSearchBeneficiary from "../../../util/AdvanceSearchWithBeneficiary";
import axios from "../../../util/Api";
import {PrintOutlined} from "@material-ui/icons";
import CircularProgress from "../../../components/CircularProgress";

const Search = Input.Search;
const Panel = Collapse.Panel;

const AllVoucherList = () => {
    const value = 0;
    const history = useHistory();
    const [state, setState] = useState({
        reqURL: Config.apiserver+jsonfile.urls.list,
        filtervalue : '',
        subtitle : '',
    });
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    useEffect(() => {
        // setState(
        //     {
        //         reqURL: Config.apiserver+jsonfile.urls.list
        //     }
        // );
        getData()
    }, [state.reqURL]);
    
    const getData = (url = "") => {
        let urls = state.reqURL;
        if(url !== "") {
            urls = url;
        }
        document.getElementById("loader").style.display = "block";
        axios.get(urls)
            .then((res) => {
                console.log(res)
                if (res.data.status === 1) {
                    setData(res.data.data);
                } else {
                    setData([])
                    notification.warning({
                        message: 'Alert',
                        type: "warning",
                        description: res.data.msg
                    });
                }
                document.getElementById("loader").style.display = "none";
            })
            .catch(errors => {
                console.log(errors);
                document.getElementById("loader").style.display = "none";
            })
    }

    

    const getPrintAction = (row) => {
        if(row.is_multy === 0) {
            return <Link to={"../"+jsonfile.urls.mview+"/"+row.voucher_no}><span style={{fontWeigh: 'bold'}}>{row.voucher_no}</span></Link>
        }
        else {
            return <Link to={"../"+jsonfile.urls.mview+"/"+row.voucher_no}><span style={{fontWeigh: 'bold'}}>{row.voucher_no}</span></Link>
        }
    }
    
    const checkClearUnclear = (id) => {
        document.getElementById("loader").style.display = "block";
        // setLoading(true)
        axios.post(Config.apiserver+"entries/clearcheck/"+id,null)
            .then(res => {
                // setLoading({loading: false});
                // document.getElementById("loader").style.display = "none";
                if (res.data.status === 1) {
                    CisUI().showAlert("Success","success",res.data.msg);
                    // setState({
                    //     reqURL: state.reqURL
                    // })
                    getData(state.reqURL)
                } else {
                    CisUI().showAlert("Alert","warning",res.data.msg);
                }
                document.getElementById("loader").style.display = "none";
                // setLoading({loading: false});
            })
            .catch(errors => {
                // console.log(errors.response.data.errors);
                // setLoading({loading: false});
                document.getElementById("loader").style.display = "none";
            });
    }
    
    const FileOpen = (fileID) => {
        return window.open(Config.attachement + fileID, "_blank").focus();
    }

    const columns = [
        {
            title: <IntlMessages id='Actions' />,
            dataIndex: 'action',
            key: 'action',
            width: "70px",
            fixed: "left",
            render: (value, row, index) => (
                <>
                    {
                        row.is_multy === 1 ?
                            row.status !==3 ? ActionButton2(row.id,jsonfile.urls2,jsonfile.urls.list) : "" :
                            row.status !==3 ? ActionButton2(row.id,jsonfile.urls,jsonfile.urls.list) : ""
                    }
                    <a href={"/voucherprintv1.html?id="+row.voucher_no} target="_blank" className={"btn btn-primary btn-circle"}><i className="fas fa-print" /></a>
                </>
            ),
        },
        {
            title: <IntlMessages id="Check Status"/>,
            dataIndex: "checque_status",
            key: "checque_status",
            width: "120px",
            render: (value, row, index) => (
                <>
                    <Popconfirm
                        placement="topRight"
                        title={value === 1 ? "Are you sure you want to unclear this voucher ?" : "Are you sure you want to clear this voucher ?"}
                        onConfirm={() => checkClearUnclear(row.id)}
                        okText="Yes"
                        cancelText="No"
                    >

                        <a href="#">
                            {CisUI().getChecqueStatus(value)}
                        </a>
                    </Popconfirm>
                </>
            ),
        },
        {
            title: <IntlMessages id="Date"/>,
            dataIndex: "date",
            key: "date",
            width: "100px",
            render: (value, row, index) => CisUI().DateFormat(value)
        },
        {
            title: <IntlMessages id="Voucher No#"/>,
            dataIndex: "voucher_no",
            key: "voucher_no",
            width: "100px",
            render: (value, row, index) => getPrintAction(row)
        },
        {
            title: <IntlMessages id="Account"/>,
            dataIndex: "head",
            key: "head",
            width: "250px",
            // render: (value, row, index) => row.payment_head !== null ? row.payment_head : row.receipt_head
        },
        {
            title: <IntlMessages id="Receipt(₵)"/>,
            dataIndex: "debit",
            key: "debit",
            align: 'right',
            width: "100px",
            render: (value, row, index) => CisUI().getCurrencyFormattedV2(value)
        },
        {
            title: <IntlMessages id="Payment(₵)"/>,
            dataIndex: "credit",
            key: "credit",
            align: 'right',
            width: "100px",
            render: (value, row, index) => CisUI().getCurrencyFormattedV2(value)
        },
        {
            title: <IntlMessages id="Payment Method"/>,
            dataIndex: "type",
            key: "type",
            width: "120px",
        },
        {
            title: <IntlMessages id="Reference"/>,
            dataIndex: "reference",
            key: "reference",
            width: "80px",
        },
        {
            title: <IntlMessages id="Created By"/>,
            dataIndex: "user_name",
            key: "user_name",
            width: "200px"
        },
        {
            title: <IntlMessages id="Beneficiary"/>,
            dataIndex: "source",
            key: "source",
            width: "300px"
        },
        {
            title: <IntlMessages id="Attachment"/>,
            dataIndex: "attachment",
            key: "attachment",
            width: "100px",
            render: (value, row, index) => (
                value.length > 0 && value.map((item, index) =>
                    <a onClick={()=>FileOpen(item.location)}>
                        <button className="btn btn-primary btn-circle">
                            <i className="fas fa-folder-open"/>
                        </button>
                        {/*<span style={{paddingLeft: '5px'}}><IntlMessages id="View" /></span>*/}
                    </a>
                )
            )
        },
        {
            title: <IntlMessages id="Status"/>,
            dataIndex: "status",
            key: "status",
            width: "100px",
            render: (value, row, index) => CisUI().getTransactionStatus(value)
        },
        
    ];
    
    const onFinish = values => {
        console.log('Received values of form: ', values);
        const qsup = CisUI().getQueryStrings(values);
        //reqURL = reqURL+"/?"+qsup;
        history.push("?"+qsup);
        const newURL = Config.apiserver+jsonfile.urls.list+"/?"+qsup;
        setState({reqURL: newURL});
    };

    let  searchView = "";
    if(jsonfile.has_search) {
        searchView = <Card className="advance-search-card">
            <Collapse
                accordion
                expandIconPosition="right"
                defaultActiveKey={['1']} >
                <Panel header="Advance Search"  key="1">
                    <Form
                        key={5}
                        form={form}
                        {...CisUI().formItemLayout}
                        name="advanced_search"
                        className="ant-advanced-search-form"
                        onFinish={onFinish}
                    >
                        {AdvancedSearchBeneficiary(jsonfile.input,"",1,"date",CisUI().PeriodFiled(form),form)}
                    </Form>
                </Panel>
            </Collapse>
        </Card>
    }

    const getExtra = (
        <>
            {CisUI().listAction("../"+jsonfile.urls.add,"",state.reqURL,"data",'all_transaction', jsonfile, state.subtitle, "landscape")}
        </>
    );
    
    // if(loading){
    //     return <CircularProgress />;
    // }

    return (
        <>
            {CisUI().showLoading}
            {searchView}
            <Card title={jsonfile.listtitle} extra={getExtra}>
                {
                    data.length > 0 ?
                        <Table
                            // className="ledgerTable"
                            columns={columns}
                            // pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '30', '50', '100','200','500']}}
                            pagination={{ defaultPageSize: 50, position: ['bottomCenter'] }}
                            dataSource={data}
                            scrollToFirstRowOnChange = {true}
                            expandable={{
                                defaultExpandAllRows: true
                            }}
                            scroll={{
                                x: 1500
                            }}
                        />
                        :
                        ''
                }
            </Card>
        </>
    );
};

export default AllVoucherList;