import React, {useState} from "react";
import {
	Row,
	Col,
	Form,
	Card,
	Input,
	Button,
	notification,
	Divider,
	Checkbox,
	Select,
	Upload,
	DatePicker,
	InputNumber
} from 'antd';
import jsonfile from './general.json';
import {useHistory} from "react-router-dom";
import RemoteJson from "../../../../../util/gerremotedata";
import {CisUI} from "../../../../../util/CISUI";
import Config from "../../../../../util/config";
import axios from "axios";
import {UploadOutlined} from "@ant-design/icons";

const GeneralInfo = (props) => {
	const [amountTitle, setAmountTitle] = useState("Amount");
	const [amount, setAmount] = useState(1);
	const history = useHistory();
	const {form} = Form.useForm();

	const userID = props.editEmpId;
	let userData = [];
	// console.log("Userid : "+props.match.params.id);
	let isEdit = 0;
	if(userID > 0) {
		isEdit = 1;
	}
	let Title = jsonfile.addtitle;
	let endpoint = jsonfile.urls.add;
	let redirectto = "../"+jsonfile.urls.list;

	if(isEdit === 1 ) {
		userData = RemoteJson(jsonfile.urls.view+"/"+userID);
		Title = jsonfile.edittitle;
		endpoint = jsonfile.urls.edit+"/"+userID;
		redirectto = "../../"+jsonfile.urls.list;
		// props.empid(userID);
	}
	console.log("General", userData)

	const onFinish = (values) => {
		// console.log("Input Data : " + values);
		document.getElementById("loader").style.display = "block";
		axios.post(Config.apiserver + endpoint, values, CisUI().HeaderRequest)
			.then(res => {
				// console.log(res)
				if(res.data.status === 1) {
					notification.success({
						message: 'Success',
						type : "success",
						description: res.data.msg
					});
					props.empid(res.data.emp_id)
					// history.push(redirectto);
				}
				else {
					notification.warning({
						message: 'Alert',
						type : "warning",
						description: res.data.msg
					});
				}
				document.getElementById("loader").style.display = "none";
			})
			.catch(errors => {
				console.log(errors);
			});
	}

	const handleAmountChange = (value) => {
		if(value === 2){
			setAmountTitle("Percentage")
		}
		else if(value === 3){
			setAmountTitle("Cheque No")
		}
		setAmount(value)
	}

	return (
		<Card>
			{CisUI().showLoading}
			<Form
				form={form}
				{...CisUI().formItemLayout}
				name="input"
				className="ant-advanced-search-form"
				onFinish={onFinish}
				initialValues={{ remember: true }}
			>
				<Row gutter={24}>
					<Col lg={8} md={12} sm={24} xs={24}>
						<div className="gx-form-row0">
							<Form.Item
								name="employee"
								label="Employee"
							>
								<Select
									placeholder="Please Select An Option"

								>
									<Select.Option value={1}>Emp 1</Select.Option>
									<Select.Option value={2}>Emp 2</Select.Option>
									<Select.Option value={3}>Emp 3</Select.Option>
								</Select>
							</Form.Item>
						</div>
					</Col>

					<Col lg={8} md={12} sm={24} xs={24}>
						<div className="gx-form-row0">
							<Form.Item
								name="name"
								label="Name"
							>
								<Input placeholder="Name"/>
							</Form.Item>
						</div>
					</Col>
					<Col lg={8} md={12} sm={24} xs={24}>
						<div className="gx-form-row0">
							<Form.Item
								name="address"
								label="Address"
							>
								<Input.TextArea placeholder="Enter Address"/>
							</Form.Item>
						</div>
					</Col>
					<Col lg={8} md={12} sm={24} xs={24}>
						<div className="gx-form-row0">
							<Form.Item
								name="city"
								label="City"
							>
								<Input placeholder="Enter City"/>
							</Form.Item>
						</div>
					</Col>
					<Col lg={8} md={12} sm={24} xs={24}>
						<div className="gx-form-row0">
							<Form.Item
								name="state"
								label="State"
							>
								<Input placeholder="Enter State"/>
							</Form.Item>
						</div>
					</Col>
					<Col lg={8} md={12} sm={24} xs={24}>
						<div className="gx-form-row0">
							<Form.Item
								name="zipcode"
								label="Zipcode"
							>
								<Input placeholder="Enter zipcode"/>
							</Form.Item>
						</div>
					</Col>

					<Divider orientation="left" plain><span style={{ fontSize: "16px", fontWeight: "bold"}}>Bank Information</span></Divider>
					<Col lg={8} md={12} sm={24} xs={24}>
						<div className="gx-form-row0">
							<Form.Item
								name="bank_name"
								label="Name Of Bank"
							>
								<Input placeholder="Name Of Bank"/>
							</Form.Item>
						</div>
					</Col>
					<Col lg={8} md={12} sm={24} xs={24}>
						<div className="gx-form-row0">
							<Form.Item
								name="account"
								label="Account #"
							>
								<Input placeholder="Enter Account"/>
							</Form.Item>
						</div>
					</Col>
					<Col lg={8} md={12} sm={24} xs={24}>
						<div className="gx-form-row0">
							<Form.Item
								name="rounting_number"
								label="9 Digit Routing"
							>
								<InputNumber className="gx-w-100" placeholder="Enter 9 Digit Routing Number"/>
							</Form.Item>
						</div>
					</Col>
					<Col lg={8} md={12} sm={24} xs={24}>
						<div className="gx-form-row0">
							<Form.Item
								name="amount"
								label="Amount"
								initialValue={amount}
							>
								<Select
									onChange={handleAmountChange}
									placeholder="Please Select An Option"

								>
									<Select.Option value={1}>Amount ($)</Select.Option>
									<Select.Option value={2}>Percentage (%)</Select.Option>
									<Select.Option value={3}>Entire Pay Check</Select.Option>
								</Select>
							</Form.Item>
						</div>
					</Col>
					<Col lg={8} md={12} sm={24} xs={24}>
						<div className="gx-form-row0">
							<Form.Item
								name="name"
								label={amountTitle}
							>
								<InputNumber className="gx-w-100" placeholder={`Enter ${amountTitle}`}/>
							</Form.Item>
						</div>
					</Col>
					<Col lg={8} md={12} sm={24} xs={24}>
						<div className="gx-form-row0">
							<Form.Item
								className="form-input"
								name="emp_signature_date"
								label="Signature Date"
							>
								<DatePicker className="gx-w-100" format={CisUI().dateFormat}/>
							</Form.Item>
						</div>
					</Col>
					<Col lg={8} md={12} sm={24} xs={24}>
						<div className="gx-form-row0">
							<Form.Item
								className="form-input"
								name="emp_signature"
								label="Employee SIGNATURE"
							>
								<Upload>
									<Button>
										<UploadOutlined/> Click to upload
									</Button>
								</Upload>
							</Form.Item>
						</div>
					</Col>




					{/*<Col lg={8} md={12} sm={24} xs={24}>*/}
					{/*	<div className="gx-form-row0">*/}
					{/*		<Form.Item*/}
					{/*			name="hand_over"*/}
					{/*			label="Hand Over Material"*/}
					{/*		>*/}
					{/*			<Select*/}
					{/*				placeholder="Please Select An Option"*/}
					{/*			>*/}
					{/*				<Select.Option value={1}>CHURCH VEHICLE</Select.Option>*/}
					{/*				<Select.Option value={2}>LAPTOP</Select.Option>*/}
					{/*				<Select.Option value={3}>ID CARD</Select.Option>*/}
					{/*				<Select.Option value={4}>ACCESS KEYS</Select.Option>*/}
					{/*				<Select.Option value={5}>HANDOVER NOTES</Select.Option>*/}
					{/*				<Select.Option value={6}>CHURCH BANK ACCOUNT / CHECK BOOKS</Select.Option>*/}
					{/*			</Select>*/}
					{/*		</Form.Item>*/}
					{/*	</div>*/}
					{/*</Col>*/}
					{/*<Col lg={8} md={12} sm={24} xs={24}>*/}
					{/*	<div className="gx-form-row0">*/}
					{/*		<Form.Item*/}
					{/*			name="other_materials"*/}
					{/*			label="Others Material(Please Specify)"*/}
					{/*		>*/}
					{/*			<Input.TextArea placeholder="Others Material"/>*/}
					{/*		</Form.Item>*/}
					{/*	</div>*/}
					{/*</Col>*/}
					{/*<Col lg={12} md={12} sm={24} xs={24}>*/}
					{/*	<div className="gx-form-row0">*/}
					{/*		<Form.Item*/}
					{/*			className="form-input"*/}
					{/*			name="employee_sugnature"*/}
					{/*			label="Employee Signature"*/}
					{/*		>*/}
					{/*			<Upload>*/}
					{/*				<Button>*/}
					{/*					<UploadOutlined/> Click to upload*/}
					{/*				</Button>*/}
					{/*			</Upload>*/}
					{/*		</Form.Item>*/}
					{/*	</div>*/}
					{/*</Col>*/}
				</Row>
				{/*<Row>*/}
				{/*	<Divider orientation="left" plain><span style={{ fontSize: "16px", fontWeight: "bold"}}>FOR HR USE ONLY</span></Divider>*/}
				{/*	<Col lg={12} md={12} sm={24} xs={24}>*/}
				{/*		<div className="gx-form-row0">*/}
				{/*			<Form.Item*/}
				{/*				name="hr_comment"*/}
				{/*				label="HR Comment"*/}
				{/*			>*/}
				{/*				<Input.TextArea placeholder="HR Comment"/>*/}
				{/*			</Form.Item>*/}
				{/*		</div>*/}
				{/*	</Col>*/}
				{/*	<Col lg={12} md={12} sm={24} xs={24}>*/}
				{/*		<div className="gx-form-row0">*/}
				{/*			<Form.Item*/}
				{/*				className="form-input"*/}
				{/*				name="hr_signature"*/}
				{/*				label="HR SIGNATURE"*/}
				{/*			>*/}
				{/*				<Upload>*/}
				{/*					<Button>*/}
				{/*						<UploadOutlined/> Click to upload*/}
				{/*					</Button>*/}
				{/*				</Upload>*/}
				{/*			</Form.Item>*/}
				{/*		</div>*/}
				{/*	</Col>*/}
				{/*</Row>*/}

				<Row gutter={24}>
					<Col lg={24} md={24} sm={12} xs={24} style={{textAlign: 'center'}}>
						<Button type="primary" htmlType="submit">
							Submit
						</Button>
						<Button type="info" onClick={() => history.goBack()} htmlType="submit">Back</Button>
					</Col>
				</Row>

			</Form>
		</Card>
	);
}

export default GeneralInfo;