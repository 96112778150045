import React, {useState} from "react";
import {Card, notification,Tabs} from 'antd';
import jsonfile from './deposit_authorization.json';
import {useHistory} from "react-router-dom";
import RemoteJson from "../../../../util/gerremotedata";
import {CisUI} from "../../../../util/CISUI";
import Config from "../../../../util/config";
import axios from "axios";
import GeneralInfo from "./GeneralInfo/GeneralInfo";
import WithholdCertificate from "./WithholdCertificate/WithholdCertificate";


const { TabPane } = Tabs;
const DepositAuthorization = (props) => {
	const [empId, setEmpId] = useState();

	const history = useHistory();

	const userID = props.editEmpId;
	let userData = [];
	// console.log("Userid : "+props.match.params.id);
	let isEdit = 0;
	if(userID > 0) {
		isEdit = 1;
	}
	let Title = jsonfile.addtitle;
	let endpoint = jsonfile.urls.add;
	let redirectto = "../"+jsonfile.urls.list;

	if(isEdit === 1 ) {
		userData = RemoteJson(jsonfile.urls.view+"/"+userID);
		Title = jsonfile.edittitle;
		endpoint = jsonfile.urls.edit+"/"+userID;
		redirectto = "../../"+jsonfile.urls.list;
		// props.empid(userID);
	}
	console.log("General", userData)

	const handleCallback = (childData) =>{
		// console.log(childData);
		setEmpId(childData)
	}

	const onFinish = (values) => {
		// console.log("Input Data : " + values);
		document.getElementById("loader").style.display = "block";
		axios.post(Config.apiserver + endpoint, values, CisUI().HeaderRequest)
			.then(res => {
				// console.log(res)
				if(res.data.status === 1) {
					notification.success({
						message: 'Success',
						type : "success",
						description: res.data.msg
					});
					props.empid(res.data.emp_id)
					// history.push(redirectto);
				}
				else {
					notification.warning({
						message: 'Alert',
						type : "warning",
						description: res.data.msg
					});
				}
				document.getElementById("loader").style.display = "none";
			})
			.catch(errors => {
				console.log(errors);
			});
	}


	return (
		<Card title={isEdit === 1 ? jsonfile.edittitle : jsonfile.addtitle}
		      extra={
			      <>
				      {
					      isEdit === 1
						      ? CisUI().addAction(redirectto)
						      : CisUI().addAction(redirectto)
				      }
			      </>
		      }
		>
			<Tabs defaultActiveKey={1} style={{ marginBottom: 32 }}>
				<TabPane tab="General Info" key="1">
					<GeneralInfo empid={handleCallback} editEmpId={userID}/>
				</TabPane>
				<TabPane tab="Withholding Certificate" key="2">
					<WithholdCertificate empid={empId} editEmpId={userID}/>
				</TabPane>
			</Tabs>
		</Card>
	);
}

export default DepositAuthorization;