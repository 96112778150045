import React, {useState} from "react";
import {Row, Col, Form, Card, Input, Button, notification, Checkbox, Divider} from 'antd';
import jsonfile from './personal_info.json';
import {useHistory} from "react-router-dom";
import RemoteJson from "../../../../../util/gerremotedata";
import InputFieldV2 from "../../../../../util/InputFieldV2";
import {CisUI} from "../../../../../util/CISUI";
import Config from "../../../../../util/config";
import axios from "axios";

const {TextArea} = Input
const PersonalInformation = (props) => {

	const history = useHistory();
	const {form} = Form.useForm();

	const [isShow, setIsShow] = useState(false);
	const handleResidence = (e) => {
		e.target.checked === true ? setIsShow(true) : setIsShow(false);
	}

	const userID = props.editEmpId;
	let userData = [];
	// console.log("Userid : "+props.match.params.id);
	let isEdit = 0;
	if(userID > 0) {
		isEdit = 1;
	}
	let Title = jsonfile.addtitle;
	let endpoint = jsonfile.urls.add;
	let redirectto = "../"+jsonfile.urls.list;

	if(isEdit === 1 ) {
		userData = RemoteJson(jsonfile.urls.view+"/"+userID);
		Title = jsonfile.edittitle;
		endpoint = jsonfile.urls.edit+"/"+userID;
		redirectto = "../../"+jsonfile.urls.list;
		// props.empid(userID);
	}
	console.log("General", userData)

	const onFinish = (values) => {
		// console.log("Input Data : " + values);
		document.getElementById("loader").style.display = "block";
		axios.post(Config.apiserver + endpoint, values, CisUI().HeaderRequest)
			.then(res => {
				// console.log(res)
				if(res.data.status === 1) {
					notification.success({
						message: 'Success',
						type : "success",
						description: res.data.msg
					});
					props.empid(res.data.emp_id)
					// history.push(redirectto);
				}
				else {
					notification.warning({
						message: 'Alert',
						type : "warning",
						description: res.data.msg
					});
				}
				document.getElementById("loader").style.display = "none";
			})
			.catch(errors => {
				console.log(errors);
			});
	}

	return (
		<Card>
			{CisUI().showLoading}
			<Form
				form={form}
				{...CisUI().formItemLayout}
				name="input"
				className="ant-advanced-search-form"
				onFinish={onFinish}
				initialValues={{ remember: true }}
			>
				{InputFieldV2(jsonfile.input,userData,isEdit,props)}

				<Row gutter={24}>
					<Col lg={24} md={24} sm={12} xs={24} style={{textAlign: 'center'}}>
						<Button type="primary" htmlType="submit">
							Submit
						</Button>
						<Button type="info" onClick={() => history.goBack()} htmlType="submit">Save & Submit</Button>
					</Col>
				</Row>

			</Form>
		</Card>
	);
}

export default PersonalInformation;