import {Form, Button, Upload, message, Card, Select, DatePicker, notification} from 'antd';
import React, {useState, useEffect} from "react";
import jsonfile from './invoice.json'
import {CisUI} from '../../../../../util/CISUI'
import 'react-smart-data-table/dist/react-smart-data-table.css'
import Config from "../../../../../util/config";
import axios from "../../../../../util/Api";
import {useHistory} from "react-router-dom";
import SelectData from "../../../../../util/selectdata";
import moment from "moment";
import IntlMessages from "../../../../../util/IntlMessages";
import { UploadOutlined } from '@ant-design/icons';
import CircularProgress from "../../../../../components/CircularProgress";

const {Option} = Select;

const EditInvoice = (props) => {
	let userID = props.match.params.id;
	const [form] = Form.useForm();
	const [editData, setEditData] = useState({});
	const history = useHistory();
	const [loader, setLoader] = useState(false);
	const [receivable, setReceivable] = useState([]);
	const [accounts, setAccounts] = useState([]);
	const [customer, setcustomer] = useState([]);
	const [fund, setFundProgram] = useState([]);
	const [selectedBeneficiary, setSelectedBeneficiary] = useState([]);
	const [loadingstatus, setLoadingStatus] = useState({
		'transaction': false,
		'beneficiary': false
	});

	const [beneficiary, setBeneficiary] = useState({
		'customer': [],
		'vendor': [],
		'employee': [],
		'hqdepartment': [],
		'area': [],
		'district': [],
		'local': [],
		'staff': [],
		'ministries': [],
		'pentsos': [],
		'committee': [],
		'subsectors': [],
	});

	const BeneficiaryType = SelectData("accountbenificary");
	const Local = SelectData("alllocal");

	const [bill, setBill] = useState(
		{
			total : 0,
			grand_total : 0,
			freight : 0,
			taxp : 0,
			tax_amount : 0,
			discount_p : 0,
			discount_amount : 0
		}
	);

	const [lineItems, setLineItems] = useState([]);
	const [totalAmount, setTotalAmount] = useState(0);


	const [comp, setComp] = useState(
		{
			com_address: "",
			com_city: "",
			com_state: "",
			com_zip_code: ""
		}
	);

	const [vend, setVend] = useState(
		{
			customer_address: "",
			customer_city: "",
			customer_state: "",
			customer_zip_code: "",
			shipping_method: ""
		}
	);

	const handleChange = (e) => {
		setComp({
			[e.target.name]: e.target.value
		})
	}
	// let userData = [];
	// console.log("Userid : "+props.match.params.id);
	let isEdit = 0;
	if (userID > 0) {
		isEdit = 1;
	}
	let Title = jsonfile.addtitle;
	let endpoint = jsonfile.urls.add;
	let redirectto = "../" + jsonfile.urls.list;

	if (isEdit === 1) {
		// userData = RemoteJson(jsonfile.urls.view + "/" + userID);
		Title = jsonfile.edittitle;
		endpoint = jsonfile.urls.edit + "/" + userID;
		redirectto = "../../" + jsonfile.urls.list;
	}



	const getBeneficiaryData = () => {
		// document.getElementById("loader").style.display = "block";
		axios.get(Config.apiserver+"beneficiarylist")
			.then((res) => {
				// document.getElementById("loader").style.display = "none";
				//console.log(res.data);
				if (res.data.status === 1) {
					const data = res.data.data;
					setBeneficiary(data);
					loadingstatus["beneficiary"] = true;
				}
			})
			.catch((error) => {
				console.log(error);
				// document.getElementById("loader").style.display = "none";
			});
	}

	useEffect(() => {
		getBeneficiaryData();
	}, []);
	const GetInvoiceDetails = () => {
		setLoader(true)
		axios.get(Config.apiserver + 'crm/invoicedetail/' + userID)
			.then((res) => {
				if (res.data.status === 1) {
					let editData = res.data.data;
					let ben = benf(res.data.data.beneficiary_source)
					console.log("editData1", ben)
					setSelectedBeneficiary(ben);
					setEditData(editData)
				}
				setLoader(false)
			})
			.catch((error) => {
				console.log(error);
			});
	}

	useEffect(() => {
		if (userID > 0) {
			GetInvoiceDetails()
			const comID = CisUI().getUserInfo('com_id') || 0;
			if (CisUI().getUserInfo('com_id') > 0) {
				onCompanyChangeForCompany(comID);
			}
		}
	}, [beneficiary]);

	useEffect(() => {
		if (editData) {
			form.setFieldsValue({
				...editData,
				date: moment(editData?.date)
			});

			let item_data = []
			editData?.items?.map((item, index) => {
				item_data.push({
					id: item?.id,
					account_id: item?.account_id,
					fund_program_id: item?.fund_program_id,
					func_expense_id: item?.func_expense_id,
					restriction_type: item?.restriction_type,
					amount: item?.total,
					note: item?.name,
					classification : item?.classification,
					files : item?.files,
					file_location : item?.file_location
				})
			})
			setTotalAmount(editData?.total)
			setLineItems(item_data)
		}
	}, [editData])

	const benf = (e) => {
		let ben = [];
		if (e === 2) {
			ben = beneficiary.customer;
		} else if (e === 3) {
			ben = beneficiary.vendor;
		} else if (e === 4) {
			ben = beneficiary.employee;
		} else if (e === 6) {
			ben = beneficiary.hqdepartment;
		} else if (e === 7) {
			ben = beneficiary.area;
		} else if (e === 8) {
			ben = beneficiary.employee;
		} else if (e === 9) {
			ben = beneficiary.employee;
		} else if (e === 10) {
			ben = beneficiary.ministries;
		} else if (e === 11) {
			ben = beneficiary.pentsos;
		} else if (e === 12) {
			ben = beneficiary.district;
		} else if (e === 13) {
			ben = Local;
		} else if (e === 14) {
			ben = beneficiary.committee;
		} else if (e === 15) {
			ben = beneficiary.subsectors;
		}
		return ben;
	}

	const onBenificaryChange = (e) => {
		//setBeneficiaryselected(e.target.value);
		console.log("Selected : ", e);
		if (e === 2) {
			setSelectedBeneficiary(beneficiary.customer);
		} else if (e === 3) {
			setSelectedBeneficiary(beneficiary.vendor);
		} else if (e === 4) {
			setSelectedBeneficiary(beneficiary.employee);
		} else if (e === 6) {
			setSelectedBeneficiary(beneficiary.hqdepartment);
		} else if (e === 7) {
			setSelectedBeneficiary(beneficiary.area);
		} else if (e === 8) {
			setSelectedBeneficiary(beneficiary.employee);
		} else if (e === 9) {
			setSelectedBeneficiary(beneficiary.employee);
		} else if (e === 10) {
			setSelectedBeneficiary(beneficiary.ministries);
		} else if (e === 11) {
			setSelectedBeneficiary(beneficiary.pentsos);
		} else if (e === 12) {
			setSelectedBeneficiary(beneficiary.district);
		} else if (e === 13) {
			setSelectedBeneficiary(Local);
		}
		else if (e === 14) {
			setSelectedBeneficiary(beneficiary.committee);
		}
		else if (e === 15) {
			setSelectedBeneficiary(beneficiary.subsectors);
		}

		form.setFieldsValue({
			beneficiary_id: null
		});

	}

	const onFinish = (values) => {
		values["items"] = lineItems;
		values["summary"] = bill;
		console.log(JSON.stringify(values));
		document.getElementById("loader").style.display = "block";
		axios.post(Config.apiserver + endpoint, values)
			.then(res => {
				document.getElementById("loader").style.display = "none";
				console.log(res)
				if (res.data.status === 1) {
					notification.success({
						message: 'Success',
						type: "success",
						description: res.data.msg
					});
					history.push(redirectto);
				} else {
					notification.warning({
						message: 'Alert',
						type: "warning",
						description: res.data.msg
					});
				}

			})
			.catch(errors => {
				// document.getElementById("loader").style.display = "none";
				console.log(errors);
			});
	};

	const onCompanyChangeForCompany = () => {
		const value = CisUI().getUserInfo('com_id') || 0;
		console.log("selected : "+ value);
		handleAddRow();
		axios.get("customer/customerinfobycompany?com_id="+value)
			.then((res) => {
				console.log(res.data);
				if(res.data.status === 1) {
					setcustomer(res.data.data);
					setReceivable(res.data.receiveable);
					const Account = res.data.accounts;
					const sAct = Account.filter((item)=> item.act_type === 11);
					setAccounts(sAct);
					setFundProgram(res.data.fund);
				}
			})
			.catch((error) => {
				console.log(error);
			});
	}

	const ItemCellChange = idx => e => {
		const { name, value } = e.target;
		const rows = [...lineItems];
		console.log("selectedITems:"+ JSON.stringify(rows[idx]));

		if(name === "amount") {
			rows[idx] = {
				id: rows[idx].id,
				account_id: rows[idx].account_id,
				fund_program_id: rows[idx].fund_program_id,
				func_expense_id: rows[idx].func_expense_id,
				restriction_type: rows[idx].restriction_type,
				amount: value,
				note: rows[idx].note,
				classification : rows[idx].classification,
				files : rows[idx].files,
				file_location : rows[idx].file_location,
			};
		}
		else if(name === "note") {
			rows[idx] = {
				id: rows[idx].id,
				account_id: rows[idx].account_id,
				fund_program_id: rows[idx].fund_program_id,
				func_expense_id: rows[idx].func_expense_id,
				restriction_type: rows[idx].restriction_type,
				amount: rows[idx].amount,
				note: value,
				classification : rows[idx].classification,
				files : rows[idx].files,
				file_location : rows[idx].file_location,
			};
		}

		setLineItems(rows);

		let ttl = 0;
		rows.map((item,index) => (
			ttl += parseFloat(item.amount)
		))
		setTotalAmount(ttl);

	};

	const ItemCellChangeAccount = idx => e => {
		console.log("selectedValued:" + e);
		console.log("selectedValuedID:" + idx);

		const rows = [...lineItems];
		rows[idx] = {
			account_id: e,
			fund_program_id: rows[idx].fund_program_id,
			func_expense_id: rows[idx].func_expense_id,
			restriction_type: rows[idx].restriction_type,
			amount: rows[idx].amount,
			note: rows[idx].note,
			classification : rows[idx].classification,
			files : rows[idx].files,
			file_location : rows[idx].file_location,
		};

		setLineItems(rows);

	}

	const handleAddRow = () => {
		const item = {
			id: 0,
			account_id: null,
			fund_program_id: 1,
			func_expense_id: 1,
			restriction_type: "without",
			amount: null,
			note: null,
			classification : null,
			files : null,
			file_location : null,
		};
		setLineItems([...lineItems, item]);
	};

	const handleRemoveRow = idx => e => {
		// console.log(idx);
		// console.log(state.rows);
		let filteredArray = lineItems.filter((item, index) => index !== idx)
		setLineItems(filteredArray);

		let ttl = 0;
		filteredArray.map((item,index) => {
			ttl += parseFloat(item.amount);
		})
		setTotalAmount(ttl);

	};


	const token = localStorage.getItem('token');
	let formData = new FormData();
	let fileUID = "";
	const uplod = {
		name: 'file',
		action: Config.apiserver+"fileupload",
		method : 'post',
		headers: {
			'Authorization': token,
		},
		beforeUpload(file) {
			const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'application/pdf';
			if (!isJpgOrPng) {
				message.error('You can only upload jpg, png,and pdf file only!');
			}
			return isJpgOrPng;
		},
		onChange(info) {
			formData.append('file', info.file);
			fileUID = info.file.uid;
			console.log("index : "+info.file.uid);
			console.log(JSON.stringify(info));
			if(info.file.status === "done") {
				if (info.file.xhr.status === 200) {
					console.log("ServerResponse : "+info.file.response);
					const data = info.file.response;
					if(data.status === 1) {
						const list = [...lineItems];
						let files = list[data.line_index].files || [];
						let file_location = list[data.line_index].file_location || [];
						files.push(data.data.id);
						file_location.push(data.data.location);
						list[data.line_index].files = files;
						list[data.line_index].file_location = file_location;
						setLineItems(list);
						console.log("Files : "+JSON.stringify(list[data.line_index]));
						message.success(`${info.file.name} file uploaded successfully`);
					}

				} else {
					message.error(`${info.file.name} file upload failed.`);
				}
			}
		}
	}

	if(loader){
		return <CircularProgress />;
	}

	return (
		<Card title={<IntlMessages id={Title}/>} extra={
			isEdit === 1
				? CisUI().addAction('../../' + jsonfile.urls.list)
				: CisUI().addAction('../' + jsonfile.urls.list)
		}>
			{CisUI().showLoading}
			<Form
				form={form}
				{...CisUI().formItemLayout}
				name="input"
				className="ant-advanced-search-form"
				onFinish={onFinish}
				initialValues={{remember: true}}
			>
				<div className="row">
					<div className="col-md-4 col-sm-6">
						<div className="gx-form-row0">
							<Form.Item
								name="beneficiary_source"
								label="Beneficiary/Source Type"
								rules={[
									{
										required: true,
										message: "Select a Beneficiary/source"
									},
								]}
							>
								<Select
									showSearch
									placeholder="Select an payment source"
									optionFilterProp="children"
									onChange={onBenificaryChange}
									filterOption={(input, option) => option.props.children?.toString().toLowerCase().indexOf(input?.toLowerCase()) >= 0 || false}
								>
									{
										BeneficiaryType.map((items, index) =>
											<Option key={++index} value={items.id}>{items.name}</Option>
										)
									}
								</Select>
							</Form.Item>
						</div>
					</div>

					<div className="col-md-4 col-sm-6">
						<div className="gx-form-row0">
							<Form.Item
								name="beneficiary_id"
								label="Beneficiary"
								rules={[
									{
										required: true,
										message: "Select a beneficiary"
									},
								]}
							>
								<Select
									showSearch
									placeholder="Select an beneficiary"
									optionFilterProp="children"
									filterOption={(input, option) => option.props.children?.toString().toLowerCase().indexOf(input?.toLowerCase()) >= 0 || false}
								>
									{
										selectedBeneficiary.map((items, index) =>
											<Option key={++index} value={items.id}>{items.name}</Option>
										)
									}
								</Select>
							</Form.Item>
						</div>
					</div>

					<div className="col-md-4">
						<Form.Item
							name="receivable_id"
							label={<IntlMessages id="Accounts Receivable"/>}
							rules={[
								{
									required: true,
									message: "Select a accounts receivable"
								},
							]}
						>
							<Select
								showSearch
								placeholder="Select a receivable account"
								optionFilterProp="children"
								filterOption={(input, option) => option.props.children?.toString().toLowerCase().indexOf(input?.toLowerCase()) >= 0 || false}
							>
								{
									receivable.map((items, index) =>
										<Option key={++index} value={items.id}>{items.name}</Option>
									)
								}
							</Select>
						</Form.Item>
					</div>

					<div className="col-md-4">
						<div className="gx-form-row0">
							<Form.Item
								name="date"
								label={<IntlMessages id="Order Date"/>}
								initialValue={moment()}
								rules={[
									{
										required: true,
										message: "Order date"
									},
								]}
							>
								<DatePicker className="gx-w-100" format={CisUI().dateFormat} placeholder="Order Date"/>
							</Form.Item>

						</div>
					</div>
				</div>

				<div className="row">
					<div className="col-md-12">
						<div className="row">
							<div className="col-md-6">
								<h2>{<IntlMessages id="Invoice Items"/>}</h2>
							</div>
							<div className="col-md-6" style={{textAlign: "right"}}>
								<a className="btn btn-primary" onClick={handleAddRow}><IntlMessages id="Add More"/></a>
							</div>
						</div>
						<table id="itemtable" className="ptable table table-bordered">
							<thead>
								<tr>
									<th>#</th>
									<th>Account</th>
									<th>Amount</th>
									<th>Description</th>
									<th>Attachment</th>
								</tr>
							</thead>
							<tbody>
							{
								lineItems.map((item, idx) => (
								<tr id="addr0" key={idx}>
								<td style={{width : '30px'}}><a onClick={handleRemoveRow(idx)} className="btn btn-primary btn-circle"><i className="fa fa-trash"></i></a></td>
									<td>
										<Select
											showSearch
											placeholder="Select an account"
											optionFilterProp="children"
											style={{width: '100%'}}
											onChange={ItemCellChangeAccount(idx)}
											value={item.account_id}
											filterOption={(input, option) => option.props.children?.toString().toLowerCase().indexOf(input?.toLowerCase()) >= 0 || false}
										>
											{
												accounts.map((items, index) =>
													<Option key={++index} value={items.id}>{items.name}</Option>
												)
											}
										</Select>
									</td>
									<td style={{width : '100px'}}>
										<input className="form-control text-right" style={{textAlign : 'right'}} placeholder="Amount" name="amount" value={item.amount} onChange={ItemCellChange(idx)}  />
									</td>
									<td><input className="form-control text-right" style={{textAlign : 'left'}} name="note" value={item.note} onChange={ItemCellChange(idx)}  /></td>

									<td style={{width : '200px'}}>
										<Upload data= {
											{
												file : formData,
												type : "invoice",
												file_id : fileUID,
												row_id : item.id,
												line_index : idx
											}
										} {...uplod}>
											<Button icon={<UploadOutlined />}>Click to Upload</Button>
										</Upload>
									</td>
								</tr>
							))}
							</tbody>
							<tfoot>
							<tr>
								<th colSpan="2">Total = </th>
								<th style={{textAlign: 'right', fontSize: '15px'}}>{CisUI().getCurrencyFormated1(totalAmount || 0)}</th>
							</tr>
							</tfoot>
						</table>
					</div>
				</div>

				<div className="row">
					<div className="col-md-12" style={{textAlign: 'center', marginTop : '30px'}}>
						{
							editData?.paid_amount === 0 &&
							<Button id="submitbutton" type="primary" htmlType="submit">
								<IntlMessages id="Edit Invoice"/>
							</Button>
						}
						<Button type="info" onClick={() => history.goBack()} htmlType="back"><IntlMessages id="Cancel"/></Button>
					</div>
				</div>

			</Form>
		</Card>
	);
};

export default EditInvoice;