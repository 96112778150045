import React, {useState} from "react";
import {Form, Card, notification, Tabs} from 'antd';
import jsonfile from './PersonalInfo/personal_info.json'
import {CisUI} from '../../../../util/CISUI'
import 'react-smart-data-table/dist/react-smart-data-table.css'
import Config from "../../../../util/config";
import axios from "axios";
import {Link, useHistory} from "react-router-dom";
import RemoteJson from "../../../../util/gerremotedata";
import IntlMessages from "../../../../util/IntlMessages";
import PersonalInformation from "./PersonalInfo/PersonalInformation";
import EmergencyContact from "./EmergencyContact/EmergencyContact";
import Beneficiary from "./Beneficiary/Beneficiary";
import JobInfo from "./JobInfo/JobInfo";

const { TabPane } = Tabs;
const NewEmployee = (props) => {
    const [empId, setEmpId] = useState();
    const userID = props.match.params.id;

    let userData = [];
    // console.log("Userid : "+props.match.params.id);
    let isEdit = 0;
    if(userID > 0) {
        isEdit = 1;
    }
    let Title = jsonfile.addtitle;
    let endpoint = jsonfile.urls.add;
    let redirectto = "../"+jsonfile.urls.list;
    let customerredirectto = "../"+jsonfile.urls.customerList;

    if(isEdit === 1 ) {
        userData = RemoteJson(jsonfile.urls.view+"/"+userID);
        Title = jsonfile.edittitle;
        endpoint = jsonfile.urls.edit+"/"+userID;
        redirectto = "../../"+jsonfile.urls.list;
        customerredirectto = "../../"+jsonfile.urls.customerList;
    }


    const [form] = Form.useForm();
    const history = useHistory();

    const handleCallback = (childData) =>{
        // console.log(childData);
        setEmpId(childData)
    }

    const onFinish = (values) => {

        document.getElementById("loader").style.display = "block";
        axios.post(Config.apiserver + endpoint, values, CisUI().HeaderRequest)
            .then(res => {
                console.log(res)
                if(res.data.status === 1) {
                    notification.success({
                        message: 'Success',
                        type : "success",
                        description: res.data.msg
                    });

                    values.lead_status == "customer" ? history.push(customerredirectto) : history.push(redirectto);

                }
                else {
                    notification.warning({
                        message: 'Alert',
                        type : "warning",
                        description: res.data.msg
                    });
                }
                document.getElementById("loader").style.display = "none";
            })
            .catch(errors => {
                console.log(errors);
            });
    };

    return (
        <Card title={<IntlMessages id={Title}/>} extra={
            <>
                {
                    isEdit === 1
                        ? CisUI().addAction(redirectto)
                        : CisUI().addAction(redirectto)
                }
            </>
        }>
            <Tabs defaultActiveKey={1} style={{ marginBottom: 32 }}>
                <TabPane tab="Personal Info" key="1">
                    <PersonalInformation empid={handleCallback} editEmpId={userID}/>
                </TabPane>
                <TabPane tab="Emergency Contact" key="2">
                    <EmergencyContact empid={empId} editEmpId={userID}/>
                </TabPane>
                <TabPane tab="Beneficiary" key="3">
                    <Beneficiary empid={empId} editEmpId={userID}/>
                </TabPane>
                <TabPane tab="Job Information" key="4">
                    <JobInfo empid={empId} editEmpId={userID}/>
                </TabPane>
            </Tabs>
        </Card>
    );
};

export default NewEmployee;